import { BASE_URL } from './constants';

export const recordCardholder = async ({
  token,
  cardholderId,
  ssn,
  dob,
  address1,
  address2,
  city,
  state,
  zip,
  country_code,
  occupation,
  cpId
}) => {
  const response = await fetch(`${BASE_URL}/cards/users`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cardholder_id: cardholderId,
      ssn,
      birthdate: dob,
      address1,
      address2,
      city,
      state,
      postal_code: zip,
      country_code,
      occupation,
      cp_id: cpId
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const fetchCards = async ({ token, cardholderId }) => {
  const response = await fetch(`${BASE_URL}/cards/fetch`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      cardholder_id: cardholderId
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getCards = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/cards`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getCard = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/card`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const createCard = async ({ token, address, physical, embossLine }) => {
  const response = await fetch(`${BASE_URL}/cards`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      address,
      physical,
      emboss_line: embossLine
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const toggleFreezeCard = async ({ token, cardId }) => {
  const response = await fetch(`${BASE_URL}/cards/${cardId}/toggle_freeze`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const replaceCard = async ({
  token,
  cardId,
  address,
  reason,
  embossLine
}) => {
  const response = await fetch(`${BASE_URL}/cards/${cardId}/replace`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      reason,
      address,
      emboss_line: embossLine
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getTransactions = async ({
  token,
  offset,
  limit,
  includeTransactions = true,
  includePayments = true
}) => {
  let data = {
    transactions: includeTransactions,
    payments: includePayments
  };
  if (offset) {
    data['offset'] = offset;
  }
  if (limit) {
    data['limit'] = limit;
  }

  const queryParams = new URLSearchParams(data);
  const query = queryParams.toString();

  const response = await fetch(
    `${BASE_URL}/cards/transactions${query ? '?' + query : ''}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getPendingTransactions = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/cards/transactions/pending`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// deprecated
export const getTransactionsForSettlement = async ({ token, settlementId }) => {
  const response = await fetch(
    `${BASE_URL}/cards/transactions/${settlementId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse;
};

// creates a waitlist entry if not allowed
export const checkEligibility = async ({ token, region }) => {
  const response = await fetch(`${BASE_URL}/cards/waitlist`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      region
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// checks user's existing address if no region is provided
export const getEligibility = async ({ token, region }) => {
  const query = region ? `?region=${region}` : '';
  const response = await fetch(`${BASE_URL}/cards/waitlist${query}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const postToken = async ({ token, aptoToken, aptoId }) => {
  const response = await fetch(`${BASE_URL}/cards/token`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      apto_token: aptoToken,
      apto_id: aptoId
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// export const signPayload = async ({ token, payload }) => {
//   const response = await fetch(`${BASE_URL}/cards/sign`, {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify({ payload })
//   });

//   const jsonResponse = await response.json();
//   return jsonResponse;
// }

export const startVerification = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/cards/start-verification`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const createUserSignature = async ({
  token,
  ssn,
  birthdate,
  verification_id
}) => {
  const response = await fetch(`${BASE_URL}/cards/create-user-signature`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ssn, birthdate, verification_id })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getActiveAgreements = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/cards/active-agreements`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getAccountStats = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/cards/account-stats`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getReferralStats = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/referral-stats`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getRecurringTransactions = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/transactions/recurring`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateRecurringTransaction = async ({ id, enabled, token }) => {
  const response = await fetch(`${BASE_URL}/transactions/recurring/${id}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ enabled })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateEmbossLine = async ({ token, embossLine }) => {
  const response = await fetch(`${BASE_URL}/users/emboss-line`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ emboss_line: embossLine })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const selectFreeProduct = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/product/non-membership`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const selectNonFinancialProduct = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/product/non-financial`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// for migrating from the non-financial product to the financial product
export const openFinancialProduct = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/product/financial-upgrade`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const redeemCashbackPoints = async ({ token, points }) => {
  const response = await fetch(`${BASE_URL}/cards/redeem-points`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ points })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

