import React from 'react';

import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

import RedirectComponent from './RedirectComponent';
import LayoutBlank from './shared/layouts/LayoutBlank';
import MainLayout from './shared/layouts/MainLayout';
import Loader from './shared/Loader';

// Lazy load component
const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = MainLayout; //Layout1;

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const routes = [
  // any routes
  {
    path: '/apply',
    component: lazy(() => import('./features/Card/promo/ApplyController')),
    layout: LayoutBlank
  },
  {
    path: '/apply/welcome',
    component: lazy(() => import('./features/Card/CardIntroController')),
    layout: LayoutBlank
  },
  { // unused
    path: '/apply/how-it-works',
    component: lazy(() => import('./features/Card/HowItWorksController')),
    layout: LayoutBlank
  },
  {
    path: '/apply/how-it-works-1',
    component: lazy(() => import('./features/Card/HowItWorksCashbackController')),
    layout: LayoutBlank
  },
  {
    path: '/apply/how-it-works-2',
    component: lazy(() => import('./features/Card/HowItWorksCashbackController')),
    layout: LayoutBlank
  },
  {
    path: '/apply/how-it-works-3',
    component: lazy(() => import('./features/Card/HowItWorksCashbackController')),
    layout: LayoutBlank
  },
  // public routes
  {
    path: '/',
    component: lazy(() => import('./features/Login/promo/CardLoginController')),
    layout: LayoutBlank
  },
  {
    path: '/session-expired',
    component: lazy(() => import('./features/Login/SessionExpiredController')),
    layout: LayoutBlank,
    requiresLogin: false
  },
  {
    path: '/logout',
    component: lazy(() => import('./features/Login/LogoutController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/password_resets/new',
    component: lazy(
      () => import('./features/ResetPassword/ResetPasswordController')
    ),
    layout: LayoutBlank,
    requiresLogin: false
  },
  {
    path: '/password_resets/:digest/edit',
    component: lazy(
      () => import('./features/ResetPassword/UpdatePasswordController')
    ),
    layout: LayoutBlank,
    requiresLogin: false
  },
  // referral
  {
    path: '/referral/:referral_code',
    component: lazy(() => import('./features/Referral/ReferralController')),
    layout: LayoutBlank,
    requiresLogin: false
  },
  //promo routes
  {
    path: '/apply/register',
    component: lazy(
      () => import('./features/Register/promo/RegisterController')
    ),
    layout: LayoutBlank,
    requiresLogin: false
  },
  {
    path: '/apply/login',
    component: lazy(() => import('./features/Login/promo/CardLoginController')),
    layout: LayoutBlank,
    requiresLogin: false
  },

  // SSO login - might be unnecessary?
  {
    path: '/oauth/login',
    component: lazy(() => import('./features/Oauth/authorize/LoginController')),
    layout: LayoutBlank
  },
  {
    path: '/oauth/verify-phone',
    component: lazy(
      () => import('./features/Oauth/authorize/VerifyPhoneController')
    ),
    layout: LayoutBlank
  },

  // private routes
  {
    path: '/apply/welcome-back',
    component: lazy(
      () => import('./features/WelcomeBack/WelcomeBackController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/dev',
    component: lazy(() => import('./features/Home/HomeController')),
    requiresLogin: true
  },
  {
    path: '/settings',
    component: lazy(() => import('./features/Settings/SettingsController')),
    requiresLogin: true
  },
  {
    path: '/settings/cancel-membership',
    component: lazy(
      () => import('./features/Card/dashboard/CancelMembershipController')
    ),
    requiresLogin: true
  },
  {
    path: '/settings/close-account',
    component: lazy(
      () => import('./features/Card/dashboard/CloseAccountController')
    ),
    requiresLogin: true
  },
  {
    path: '/oauth',
    component: lazy(() => import('./features/Oauth/OauthController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/oauth',
    component: lazy(() => import('./features/Oauth/OauthController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/oauth',
    component: lazy(() => import('./features/Oauth/OauthController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/oauth-update',
    component: lazy(() => import('./features/Oauth/OauthController')),
    requiresLogin: true
  },
  {
    path: '/activate',
    component: () => (
      <RedirectComponent to="/settings" state={{ tab: 'card' }} />
    ),
    requiresLogin: true
  },
  {
    path: '/faq',
    component: lazy(() => import('./features/dashboard/settings/faq/Faq')),
    requiresLogin: true
  },
  {
    path: '/financial-tools',
    component: lazy(
      () => import('./features/FinancialTools/components/FinancialTools')
    ),
    requiresLogin: true,
    config: { isCollapsed: true }
  },
  {
    path: '/register-plaid',
    component: lazy(
      () => import('./features/Register/RegisterPlaidController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/register-plaid-exit',
    component: lazy(
      () => import('./features/Register/RegisterPlaidExitController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/refresh-plaid',
    component: lazy(() => import('./features/Card/RefreshPlaidController')),
    requiresLogin: true
  },
  {
    path: '/card/dashboard',
    component: lazy(() => import('./features/Card/DashboardController')),
    requiresLogin: true
  },
  {
    path: '/card/options',
    component: () => <RedirectComponent to="/settings" />,
    requiresLogin: true
  },
  {
    path: '/card/options/terms',
    component: () => <RedirectComponent to="/settings" />,
    requiresLogin: true
  },
  {
    path: '/card/options/debit-card',
    component: () => <RedirectComponent to="/settings" />,
    requiresLogin: true
  },
  {
    path: '/card/credit-report',
    component: lazy(() => import('./features/Card/CreditReportController')),
    requiresLogin: true
  },
  {
    path: '/apply/debit-card-start',
    component: lazy(() => import('./features/Card/DebitCardStartController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/need-more-info',
    component: lazy(() => import('./features/Card/NeedMoreInfoController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/error',
    component: lazy(() => import('./features/Card/ErrorController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/cancel',
    component: lazy(() => import('./features/Card/CancelController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/application-failed',
    component: lazy(
      () => import('./features/Card/ApplicationFailedController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  // {
  //   path: '/card/update-details',
  //   component: lazy(() => import('./features/Card/UpdateDetailsController')),
  //   requiresLogin: true,
  //   layout: LayoutBlank
  // },
  {
    path: '/card/manage',
    component: () => <RedirectComponent to="/settings" />,
    requiresLogin: true
  },
  {
    path: '/card/view-statements',
    component: () => <RedirectComponent to="/settings" />,
    requiresLogin: true
  },
  {
    path: '/card/application-accepted',
    component: lazy(
      () => import('./features/Card/ApplicationAcceptedController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/not-ready-yet',
    component: lazy(() => import('./features/Card/WaitlistController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/refer',
    component: lazy(() => import('./features/Card/ReferralController')),
    requiresLogin: true
  },

  // promo routes
  // {
  //   path: '/apply/apply-start',
  //   component: lazy(() => import('./features/Card/promo/ApplyStartController')),
  //   requiresLogin: true,
  //   layout: LayoutBlank
  // },
  // {
  //   path: '/apply/confirm-phone',?
  //   component: lazy(() =>
  //     import('./features/Card/promo/CollectPhoneController')
  //   ),
  //   requiresLogin: true,
  //   layout: LayoutBlank
  // },
  {
    // new for unit
    path: '/apply/verify-phone',
    component: lazy(() => import('./features/Card/CollectPhoneController')),
    layout: LayoutBlank
  },
  // {
  //   path: '/apply/collect-details',
  //   component: lazy(() =>
  //     import('./features/Card/promo/CollectDetailsController')
  //   ),
  //   requiresLogin: true,
  //   layout: LayoutBlank
  // },
  {
    path: '/apply/collect-application-details',
    component: lazy(() => import('./features/Card/CollectDetailsController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/start-application',
    component: lazy(() => import('./features/Card/StartApplicationController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/customize-card',
    component: lazy(() => import('./features/Card/CustomizeVirtualCardController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/wait-for-application',
    component: lazy(
      () => import('./features/Card/WaitForApplicationController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/complete-agreements',
    component: lazy(
      () => import('./features/Card/CompleteAgreementsController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/review-agreements',
    component: lazy(() => import('./features/Card/ReviewAgreementsController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/skip-banking',
    component: lazy(() => import('./features/Card/SkipBankingController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  // {
  //   path: '/apply/collect-dob',
  //   component: lazy(() => import('./features/Card/promo/CollectDoBController')),
  //   requiresLogin: true,
  //   layout: LayoutBlank
  // },
  {
    path: '/apply/link-bank-account',
    component: lazy(() => import('./features/Card/promo/LinkBankController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/link-bank-error',
    component: lazy(() => import('./features/Card/LinkBankErrorController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/setup-pending',
    component: lazy(
      () => import('./features/Card/promo/SetupPendingController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/pending',
    component: lazy(() =>
      import('./features/Card/ApplicationPendingController')
    ),
    layout: LayoutBlank,
    requiresLogin: true
  },
  {
    path: '/apply/membership',
    component: lazy(() => import('./features/Card/NewMembershipController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/before-moving-on',
    component: lazy(() => import('./features/Card/BeforeMovingOnController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/helpful-tips',
    component: lazy(() => import('./features/Card/HelpfulTipsController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/select-payment-plan',
    component: lazy(
      () => import('./features/Card/SelectPaymentPlanController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/price/:source',
    component: lazy(() => import('./features/Card/PriceController')),
    layout: LayoutBlank
  },
  {
    path: '/apply/confirm-membership',
    component: lazy(
      () => import('./features/Card/ConfirmMembershipController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/membership-complete',
    component: lazy(
      () => import('./features/Card/WaitForMembershipController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/high-yield-checking',
    component: lazy(() => import('./features/Card/NewNonMembershipController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/continue',
    component: lazy(
      () => import('./features/Card/ContinueApplicationController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/fund-account',
    component: lazy(() => import('./features/Card/FundAccountController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/order-card',
    component: lazy(() => import('./features/Card/OrderCardController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  // {
  //   path: '/apply/card-ordered',
  //   component: lazy(() => import('./features/Card/CardOrderedController')),
  //   requiresLogin: true,
  //   layout: LayoutBlank
  // },
  {
    path: '/apply/complete-welcome',
    component: lazy(
      () => import('./features/Card/OnboardCompleteWelcomeController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/complete-direct-deposit',
    component: lazy(
      () => import('./features/Card/OnboardCompleteDirectDepositController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/complete-invite',
    component: lazy(
      () => import('./features/Card/OnboardCompleteInviteController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/apply/how-did-you-hear-about-us',
    component: lazy(
      () => import('./features/Card/HowDidYouHearAboutUsController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },

  // dashboard routes
  {
    path: '/card/dashboard/cashback-categories',
    component: lazy(() => import('./features/Card/dashboard/CashbackCategoriesController')),
    requiresLogin: true
  },
  {
    path: '/card/dashboard/refer',
    component: lazy(() => import('./features/Card/ReferralController')),
    requiresLogin: true
  },
  {
    path: '/card/dashboard/add-to-wallet',
    component: () => (
      <RedirectComponent to="/settings" state={{ tab: 'card' }} />
    ),
    requiresLogin: true
  },
  {
    path: '/card/dashboard/transaction-history',
    component: lazy(
      () => import('./features/Card/dashboard/FullHistoryController')
    ),
    requiresLogin: true
  },
  {
    path: '/card/dashboard/transfer-funds',
    component: lazy(
      () => import('./features/Card/dashboard/TransferFundsController')
    ),
    requiresLogin: true
  },
  {
    path: '/card/dashboard/manage-membership-plan',
    component: () => <RedirectComponent to="/settings" />,
    requiresLogin: true
  },
  {
    path: '/card/dashboard/perks',
    component: lazy(() => import('./features/dashboard/perks/Perks')),
    requiresLogin: true
  },
  {
    path: `/card/dashboard/perks/:slug`,
    component: lazy(() => import('./features/dashboard/perks/PartnerInfo')),
    requiresLogin: true
  },
  {
    path: '/card/dashboard/about-transfers',
    component: lazy(
      () => import('./features/Card/dashboard/AboutTransfersController')
    ),
    requiresLogin: true
  },
  {
    path: '/apply/explanation',
    component: lazy(() => import('./features/Card/ExplainerController')),
    requiresLogin: true
  },
  // embedded circle community
  {
    path: '/card/dashboard/university',
    component: lazy(() => import('./features/Card/dashboard/SequinUniversity')),
    requiresLogin: true,
    sessionTimeout: 60 * 60 * 1000,
    config: { isCollapsed: true }
  },
  {
    path: '/rewards',
    component: lazy(() => import('./features/Card/dashboard/RewardsController')),
    requiresLogin: true
  },
  // upgrade to a membership
  {
    path: '/become-a-member',
    component: lazy(() => import('./features/Card/AddMembershipController')),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/confirm-membership',
    component: lazy(
      () => import('./features/Card/ConfirmAddMembershipController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  },
  {
    path: '/card/membership-complete',
    component: lazy(
      () => import('./features/Card/CompleteAddMembershipController')
    ),
    requiresLogin: true,
    layout: LayoutBlank
  }
];
