export const fetchContent = async (query, key = null) => {
  const previewEnabled = process.env.REACT_APP_CONTENTFUL_PREVIEW_ENABLED;
  const token = previewEnabled
    ? process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN
    : process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;



  const response = await fetch(
    process.env.NODE_ENV === 'production' ?
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/` :
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/development/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authenticate the request
        Authorization: `Bearer ${token}`
      },
      // send the GraphQL query
      body: JSON.stringify({ query })
    }
  );
  const jsonResponse = await response.json();

  if (key) {
    return { key: key, content: jsonResponse }
  } else {
    return jsonResponse;
  }
};