import amplitude from 'amplitude-js';

import { mapFinancials } from '../../shared/utils';
import {
  CLOSE_CARD,
  GET_SEQUIN_CARDS,
  TOGGLE_FREEZE_CARD,
  UPDATE_EMBOSSED_LINE,
  SELECT_FREE_PRODUCT,
  SELECT_NON_FINANCIAL_PRODUCT,
  OPEN_FINANCIAL_PRODUCT
} from '../actions/card.actions';
import {
  SESSION_KEEP_ALIVE,
  UPDATE_NOTIFICATION,
  USER_AUTO_LOGIN,
  USER_BANK_ACCOUNT,
  USER_BANK_ACCOUNTS,
  USER_CLEAR_AUTH_STATUS,
  USER_GET,
  USER_GET_ACCOUNT_STATUS,
  USER_GET_ADDRESS,
  USER_GET_CARD,
  USER_GET_CARDS,
  USER_GET_CREDIT_PAYOFF_CONFIG,
  USER_GET_CREDIT_SCORES,
  USER_GET_FINANCIALS,
  USER_GET_LIABILITY_ACCOUNTS,
  USER_GET_LIABILITY_RECOMMENDATIONS,
  USER_GET_MEMBERSHIP,
  USER_GET_NOTIFICATION_SETTINGS,
  USER_INSTITUTIONS,
  USER_LOGIN,
  USER_LOGOUT,
  USER_PAYOFFS,
  USER_REGISTER,
  USER_RESET_PASSWORD_CREATE,
  USER_RESET_PASSWORD_UPDATE,
  USER_SAVE_CREDIT_SCORE,
  USER_SEQUIN_PHONE_VERIFICATION_FINISH,
  USER_SET_TRIGGER_PLAID,
  USER_UPDATE,
  USER_UPDATE_CARD,
  USER_UPDATE_CREDIT_PAYOFF_CONFIG,
  USER_UPDATE_DEFAULT_ACCOUNT,
  USER_UPDATE_NOTIFICATION_SETTING,
  USER_UPDATE_REPAYMENT_METHOD,
  USER_WRITE_SIMULATED_DEBT_PAYOFF_DATA,
  USER_UPDATE_LIABILITY_SETTINGS
} from '../actions/user.actions';

export const DEFAULT_STATE = {
  details: undefined,
  notifications: [],
  financials: [], // legacy credit-builder tool
  payoffs: [], // legacy credit-builder tool
  creditScores: [], // legacy credit-builder tool
  cards: [], // external credit cards from Plaid, legacy credit-builder tool
  cardProduct: undefined, // primary Sequin product
  sequinCard: undefined, // primary Sequin-issued card
  sequinCards: [], // all Sequin-issued cards
  institutions: [],
  bank_account: undefined, // primary
  bank_accounts: [], // all available,
  membership: undefined, // current membership subscription
  notificationSettings: [],
  liabilities: [], // liability accounts from Plaid, replaces 'cards'
  recommendations: [], // Sequin's liability payoff recommendations
  debtPayoffConfig: undefined,
  debtPayoffSimulatedData: undefined,
  isLoadingBankAccounts: false,
  address: undefined,
  error: undefined,
  paymentMethod: undefined,
  isLoading: false,
  isLoadingFinancials: false,
  isLoadingCreditScores: false,
  isLoadingPayoffs: false,
  isLoadingCards: false,
  triggerPlaid: false,
  token: undefined
};

const userReducer = (state = DEFAULT_STATE, action) => {
  const { payload, type } = action;
  // console.log('user reducer:', action);

  switch (type) {
    case `${USER_LOGOUT}_ACK`:
    case `${USER_LOGOUT}_ERR`:
      amplitude.getInstance().setUserId(null);

      window.localStorage.removeItem('sequin_token');
      window.localStorage.removeItem('apto_token');
      window.localStorage.removeItem('unit_token_read');
      window.localStorage.removeItem('unit_token_write');
      window.localStorage.removeItem('unit_token_base');

      return DEFAULT_STATE;
    case USER_SET_TRIGGER_PLAID:
      return {
        ...state,
        triggerPlaid: payload
      };
    case `${USER_LOGIN}_REQ`:
      return {
        ...state,
        details: undefined,
        error: undefined,
        isLoading: true,
        token: undefined
      };
    case `${USER_LOGIN}_ACK`:
      window.localStorage.setItem('sequin_token', payload?.token);
      return {
        ...state,
        details: {
          email: payload?.user?.email,
          firstName: payload?.user?.first_name,
          id: payload?.user?.id,
          lastName: payload?.user?.last_name,
          phoneNumber: payload?.user?.phone_number,
          phoneNumberVerified: payload?.user?.verified_phone_number,
          pronoun: payload?.user?.pronoun,
          defaultAccountId: payload?.user?.default_account_id,
          enableAlerts: payload?.user?.enable_alerts,
          enableTips: payload?.user?.enable_tips,
          accountStatus: payload?.user?.account_status,
          cardLocked: payload?.user?.card_locked,
          signupFlow: payload?.user?.signup_flow,
          cardProductType: payload?.user?.card_product_type,
          settlementDisabledAt: payload?.user?.settlement_disabled_at,
          cardModalSelection: payload?.user?.card_modal_selection,
          features: payload?.user?.feature_flags,
          credit_tool: payload?.user?.credit_tool,
          referralLink: payload?.referral_link
        },
        cardProduct: payload?.user?.card_product,
        //financials: mapFinancials(payload?.user?.financials?.data),
        //triggerPlaid: payload?.user?.financials?.not_linked,
        notifications: payload?.user?.notifications,
        error: payload?.user?.error,
        isLoading: false,
        paymentMethod: payload?.user?.payment_method,
        token: payload?.token
      };
    case `${USER_LOGIN}_ERR`:
      return {
        ...state,
        details: undefined,
        error: 'Error logging in',
        isLoading: false,
        token: undefined
      };
    case `${USER_UPDATE}_ACK`:
      return {
        ...state,
        // No user, returning a subset of the user
        details: {
          email: payload?.email,
          firstName: payload?.first_name,
          id: payload?.id,
          lastName: payload?.last_name,
          phoneNumber: payload?.phone_number,
          phoneNumberVerified: payload?.verified_phone_number,
          pronoun: payload?.pronoun,
          defaultAccountId: payload?.default_account_id,
          enableAlerts: payload?.enable_alerts,
          enableTips: payload?.enable_tips,
          accountStatus: payload?.account_status,
          //needsAptoToken: payload?.needs_apto_token,
          cardLocked: payload?.card_locked,
          signupFlow: payload?.signup_flow,
          cardProductType: payload?.card_product_type,
          settlementDisabledAt: payload?.settlement_disabled_at,
          // onboardingFinishedAt: payload?.onboarding_finished_at,
          // onboardingApprovedAt: payload?.onboarding_approved_at,
          // onboardingRejectedAt: payload?.onboarding_rejected_at,
          cardModalSelection: payload?.card_modal_selection,
          features: payload?.feature_flags,
          credit_tool: payload?.credit_tool,
          referralLink: payload?.referral_link
        },
        cardProduct: payload?.card_product
      };
    case `${USER_REGISTER}_REQ`:
      return {
        ...state,
        details: undefined,
        error: undefined,
        isLoading: true,
        token: undefined
      };
    case `${USER_REGISTER}_ACK`:
      window.localStorage.setItem('sequin_token', payload?.token);
      return {
        ...state,
        details: {
          email: payload?.user?.email,
          firstName: payload?.user?.first_name,
          id: payload?.user?.id,
          lastName: payload?.user?.last_name,
          phoneNumber: payload?.user?.phone_number,
          phoneNumberVerified: payload?.user?.verified_phone_number,
          pronoun: payload?.user?.pronoun,
          defaultAccountId: payload?.user?.default_account_id,
          enableAlerts: payload?.user?.enable_alerts,
          enableTips: payload?.user?.enable_tips,
          accountStatus: payload?.user?.account_status,
          //needsAptoToken: payload?.user?.needs_apto_token,
          cardLocked: payload?.user?.card_locked,
          signupFlow: payload?.user?.signup_flow,
          cardProductType: payload?.user?.card_product_type,
          settlementDisabledAt: payload?.user?.settlement_disabled_at,
          // onboardingFinishedAt: payload?.user?.onboarding_finished_at,
          // onboardingApprovedAt: payload?.user?.onboarding_approved_at,
          // onboardingRejectedAt: payload?.user?.onboarding_rejected_at,
          cardModalSelection: payload?.user?.card_modal_selection,
          features: payload?.user?.feature_flags,
          credit_tool: payload?.user?.credit_tool,
          referralLink: payload?.user?.referral_link
        },
        cardProduct: payload?.user?.card_product,
        notifications: payload?.user?.notifications,
        error: payload?.user?.error,
        isLoading: false,
        paymentMethod: payload?.user?.payment_method,
        token: payload?.token
      };
    case `${USER_REGISTER}_ERR`:
      return {
        ...state,
        details: undefined,
        error: 'Error registering new user',
        isLoading: false,
        token: undefined
      };
    case `${SESSION_KEEP_ALIVE}_ACK`:
      window.localStorage.setItem('sequin_token', payload?.token);
      return {
        ...state,
        token: payload?.token
      };
    case `${USER_RESET_PASSWORD_CREATE}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoading: true
      };
    case `${USER_RESET_PASSWORD_CREATE}_ACK`:
      return {
        ...state,
        error: payload?.error,
        isLoading: false
      };
    case `${USER_RESET_PASSWORD_CREATE}_ERR`:
      return {
        ...state,
        error: 'Error resetting password',
        isLoading: false
      };
    case `${USER_RESET_PASSWORD_UPDATE}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoading: true
      };
    case `${USER_RESET_PASSWORD_UPDATE}_ACK`:
      return {
        ...state,
        error: payload?.error,
        isLoading: false
      };
    case `${USER_RESET_PASSWORD_UPDATE}_ERR`:
      return {
        ...state,
        error: 'Error resetting password',
        isLoading: false
      };
    case `${USER_AUTO_LOGIN}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoading: true
      };
    case `${USER_AUTO_LOGIN}_ACK`: // fall through
    case `${USER_GET}_ACK`:
      return {
        ...state,
        details: {
          email: payload?.email,
          firstName: payload?.first_name,
          id: payload?.id,
          lastName: payload?.last_name,
          phoneNumber: payload?.phone_number,
          phoneNumberVerified: payload?.verified_phone_number,
          phoneValidUntil: payload?.phone_valid_until, // current session's phone validation expires at
          pronoun: payload?.pronoun,
          defaultAccountId: payload?.default_account_id,
          enableAlerts: payload?.enable_alerts,
          enableTips: payload?.enable_tips,
          accountStatus: payload?.account_status,
          //needsAptoToken: payload?.needs_apto_token,
          cardLocked: payload?.card_locked,
          signupFlow: payload?.signup_flow,
          cardProductType: payload?.card_product_type,
          settlementDisabledAt: payload?.settlement_disabled_at,
          // onboardingFinishedAt: payload?.onboarding_finished_at,
          // onboardingApprovedAt: payload?.onboarding_approved_at,
          // onboardingRejectedAt: payload?.onboarding_rejected_at,
          cardModalSelection: payload?.card_modal_selection,
          features: payload?.feature_flags,
          credit_tool: payload?.credit_tool,
          referralLink: payload?.referral_link
        },
        cardProduct: payload?.card_product,
        error: undefined,
        //financials: mapFinancials(payload?.financials?.data),
        notifications: payload?.notifications,
        isLoading: false,
        triggerPlaid: payload?.financials?.not_linked,
        paymentMethod: payload?.payment_method,
        token: window.localStorage.getItem('sequin_token')
      };
    case `${USER_AUTO_LOGIN}_ERR`:
      return {
        ...state,
        details: undefined,
        error: undefined, //"Error with auto login",
        isLoading: false
      };
    case `${USER_GET_FINANCIALS}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoadingFinancials: true
      };
    case `${USER_GET_FINANCIALS}_ACK`:
      return {
        ...state,
        error: undefined,
        financials: mapFinancials(payload.data),
        triggerPlaid: payload.not_linked,
        isLoadingFinancials: false
      };
    case `${USER_GET_FINANCIALS}_ERR`:
      return {
        ...state,
        financials: [],
        error: 'Error getting financials',
        isLoadingFinancials: false
      };
    case `${USER_PAYOFFS}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoadingPayoffs: true
      };
    case `${USER_PAYOFFS}_ACK`:
      return {
        ...state,
        error: undefined,
        payoffs: mapFinancials(payload),
        isLoadingPayoffs: false
      };
    case `${USER_PAYOFFS}_ERR`:
      return {
        ...state,
        payoffs: [],
        error: 'Error getting payoffs',
        isLoadingPayoffs: false
      };
    case `${USER_INSTITUTIONS}_REQ`:
      return {
        ...state,
        error: undefined
      };
    case `${USER_INSTITUTIONS}_ACK`:
      return {
        ...state,
        error: undefined,
        institutions: payload
      };
    case `${USER_INSTITUTIONS}_ERR`:
      return {
        ...state,
        institutions: [],
        error: 'Error getting institutions'
      };
    case `${USER_BANK_ACCOUNT}_REQ`:
      return {
        ...state,
        error: undefined
      };
    case `${USER_BANK_ACCOUNT}_ACK`:
      return {
        ...state,
        error: undefined,
        bank_account: payload
      };
    case `${USER_BANK_ACCOUNT}_ERR`:
      return {
        ...state,
        error: 'Error getting bank account',
        bank_account: undefined
      };
    case `${USER_BANK_ACCOUNTS}_REQ`:
      return {
        ...state,
        error: undefined,
        bank_accounts: [],
        isLoadingBankAccounts: true
      };
    case `${USER_BANK_ACCOUNTS}_ERR`:
      return {
        ...state,
        error: undefined,
        bank_accounts: [],
        isLoadingBankAccounts: false
      };
    case `${USER_BANK_ACCOUNTS}_ACK`:
      return {
        ...state,
        error: undefined,
        bank_accounts: payload,
        isLoadingBankAccounts: false
      };
    case `${USER_GET_CARDS}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoadingCards: true
      };
    case `${USER_GET_CARDS}_ACK`:
      return {
        ...state,
        cards: payload,
        error: undefined,
        isLoadingCards: false
      };
    case `${USER_GET_LIABILITY_ACCOUNTS}_ACK`:
      return {
        ...state,
        liabilities: payload
      };
    case `${USER_UPDATE_LIABILITY_SETTINGS}_ACK`:
      return {
        ...state,
        liabilities: payload
      };
    case `${USER_GET_LIABILITY_RECOMMENDATIONS}_ACK`:
      return {
        ...state,
        recommendations: payload?.aprs
      };
    case `${USER_GET_CARDS}_ERR`:
      return {
        ...state,
        cards: [],
        error: 'Error getting cards',
        isLoadingCards: false
      };
    case `${USER_GET_CARD}_ACK`:
      return {
        ...state,
        sequinCard: payload,
        error: undefined
      };
    case `${GET_SEQUIN_CARDS}_ACK`:
      return {
        ...state,
        sequinCards: payload
      };
    case `${TOGGLE_FREEZE_CARD}_ACK`: {
      let cards = state.sequinCards.map((c) => {
        if (c.id === payload.id) {
          return payload;
        } else {
          return c;
        }
      });

      let card =
        payload.id === state.sequinCard.id ? payload : state.sequinCard;

      return {
        ...state,
        sequinCards: cards,
        sequinCard: card
      };
    }
    case `${CLOSE_CARD}_ACK`: {
      let cards = state.sequinCards.map((c) => {
        if (c.id === payload.id) {
          return payload;
        } else {
          return c;
        }
      });

      let card =
        payload.id === state.sequinCard.id ? payload : state.sequinCard;

      return {
        ...state,
        sequinCards: cards,
        sequinCard: card
      };
    }
    case `${USER_UPDATE_CARD}_ACK`: {
      return {
        ...state,
        liabilities: state.liabilities.map((l) => {
          if (l.id === payload.id) {
            return payload;
          } else {
            return l;
          }
        })
      };
    }
    case `${USER_GET_CREDIT_SCORES}_REQ`:
      return {
        ...state,
        error: undefined,
        isLoadingCreditScores: true
      };
    case `${USER_GET_CREDIT_SCORES}_ACK`:
      return {
        ...state,
        error: undefined,
        creditScores: payload.length
          ? payload.map((creditScore) => ({
            score: creditScore.score,
            date: creditScore.created_at,
            id: creditScore.id
          }))
          : [],
        isLoadingCreditScores: false
      };
    case `${USER_SAVE_CREDIT_SCORE}_REQ`:
      return {
        ...state
      };
    case `${USER_SAVE_CREDIT_SCORE}_ACK`:
      console.log('saving cscore:', payload, state.creditScores);
      // var cs = state.creditScores
      var newScore = {
        id: payload.id,
        score: payload.score,
        date: payload.created_at
      };
      return {
        ...state,
        error: undefined,
        creditScores: [newScore, ...state.creditScores]
      };
    case `${USER_GET_CREDIT_SCORES}_ERR`:
      return {
        ...state,
        creditScores: undefined,
        error: 'Error getting credit scores.',
        isLoadingCreditScores: false
      };
    case `${USER_UPDATE_DEFAULT_ACCOUNT}_REQ`:
      return {
        ...state
      };
    case `${USER_UPDATE_DEFAULT_ACCOUNT}_ACK`:
      var details = state.details;
      details.defaultAccountId = payload?.default_account_id;
      return {
        ...state,
        details: details
      };
    case `${USER_UPDATE_DEFAULT_ACCOUNT}_ERR`:
      return {
        ...state,
        error: 'Error updating default account.'
      };
    case `${USER_CLEAR_AUTH_STATUS}_ACK`:
      if (payload?.plaid_auth_id) {
        let financials = state.financials.map((f) => {
          if (
            payload &&
            payload.plaid_auth_id &&
            f.plaidAuthId == payload.plaid_auth_id
          ) {
            f.requiresPlaidUpdate = false;
          }
          return f;
        });
        return {
          ...state,
          financials: financials
        };
      } else if (payload?.plaid_bank_account_id) {
        let bank_account = state.bank_account;
        bank_account.needs_plaid_refresh = false;
        return {
          ...state,
          bank_account: bank_account
        };
      }
      return;
    case `${USER_GET_ADDRESS}_ACK`:
      return {
        ...state,
        address: payload
      };
    case `${USER_UPDATE_REPAYMENT_METHOD}_ACK`:
      return {
        ...state,
        paymentMethod: payload?.method
      };
    case `${UPDATE_NOTIFICATION}_ACK`:
      return {
        ...state,
        notifications: payload
      };
    case `${USER_GET_ACCOUNT_STATUS}_ACK`:
      return {
        ...state,
        details: {
          ...state.details,
          accountStatus: payload
        }
      };
    case `${USER_GET_MEMBERSHIP}_ACK`:
      return {
        ...state,
        membership: payload
      };
    case `${USER_SEQUIN_PHONE_VERIFICATION_FINISH}_ACK`:
      return {
        ...state,
        details: {
          ...state.details,
          phoneNumberVerified: payload.verified
        }
      };
    case `${USER_GET_NOTIFICATION_SETTINGS}_ACK`:
      return {
        ...state,
        notificationSettings: payload
      };
    case `${USER_UPDATE_NOTIFICATION_SETTING}_ACK`:
      return {
        ...state,
        notificationSettings: payload
      };
    case `${USER_GET_CREDIT_PAYOFF_CONFIG}_ACK`:
      return {
        ...state,
        debtPayoffConfig: payload
      };
    case `${USER_UPDATE_CREDIT_PAYOFF_CONFIG}_ACK`:
      return {
        ...state,
        debtPayoffConfig: payload
      };
    case `${USER_WRITE_SIMULATED_DEBT_PAYOFF_DATA}`:
      return {
        ...state,
        debtPayoffSimulatedData: action.payload
      };
    case `${UPDATE_EMBOSSED_LINE}_ACK`:
      return {
        ...state,
        cardProduct: payload
      };
    case `${SELECT_FREE_PRODUCT}_ACK`:
      return {
        ...state,
        cardProduct: payload
      };
    case `${SELECT_NON_FINANCIAL_PRODUCT}_ACK`:
      return {
        ...state,
        cardProduct: payload
      };
    case `${OPEN_FINANCIAL_PRODUCT}_ACK`:
      return {
        ...state,
        cardProduct: payload
      };
    default:
      return state;
  }
};

export default userReducer;
