// Decouple the user token from the calling components.
// For authenticated APIs add user.token to the argument object.
// Also handle authentication failure.
export function authenticated({ type, func, params = {} }) {
  return (dispatch, getState) => {
    let token = getState().user.token;

    return dispatch({
      type: type,
      payload: () => func({ token, ...params })
    }).catch((error) => {
      console.log('caught: ' + error);
    });
  };
}
