import * as card from './card';
import * as plaid from './plaid';
import * as unit from './unit';
import * as user from './user';
import * as contentful from './contentful';

const api = {
  plaid,
  user,
  card,
  unit,
  contentful
};

export default api;
