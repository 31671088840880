export const BASE_URL = process.env['REACT_APP_API_URL'];
// appcues does not segregate users between environments!
export const APPCUES_USER_ID_PREFIX =
  process.env['REACT_APP_APPCUES_USER_ID_PREFIX'];

export const APTO_BASE_URL = process.env['REACT_APP_APTO_API_URL'];

export const UNIT_BASE_URL = process.env['REACT_APP_UNIT_BASE_URL'];

//export const CARD_PROGRAM_ID = 'cp:1104';
//export const CARD_PROGRAM_ID = 'cp:20220729';
export const CARD_PROGRAM_ID = 'cp:202307-initial';

export const VGS_KEY = process.env['REACT_APP_VGS_KEY'];
export const STRIPE_TEST_URL = process.env['REACT_APP_STRIPE_TEST_URL'];
export const REACT_ENV = process.env['REACT_APP_ENVIRONMENT']; // NODE_ENV doesn't handle staging

export const STRIPE_PUBLIC_KEY = process.env['REACT_APP_STRIPE_PUBLIC_KEY'];

export const SUPPORT_PHONE_VISA = '1-800-847-2911';

export const ELIGIBLE_STATES = [
  'CA',
  'CO',
  'FL',
  'ID',
  'IL',
  'IN',
  'MA',
  'MD',
  'MI',
  'NJ',
  'NY',
  'PA',
  'SC',
  'TN',
  'TX',
  'UT',
  'WA',
  'WI'
];

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];
