import {
  CLEAR_TRANSACTIONS,
  GET_ACCOUNT_STATS,
  GET_PENDING_TRANSACTIONS,
  GET_RECURRING_TRANSACTIONS,
  GET_REFERRAL_STATS,
  GET_TRANSACTIONS,
  UPDATE_RECURRING_TRANSACTION,
  USER_REDEEM_CASHBACK_POINTS
} from '../actions/card.actions';
import { USER_GET_ACCOUNT_STATEMENTS } from '../actions/user.actions';

export const DEFAULT_STATE = {
  transactions: [],
  pendingTransactions: [],
  recurringTransactions: [],
  statements: [],
  statementsToken: null,
  statementsTokenExpiresAt: null,
  ach: {},
  accountStats: {
    topCategories: [],
    weeklySpend: [],
    currentMonth: {},
    lastMonth: {}
  },
  referralStats: {
    incompleteReferralCount: null,
    completedReferrals: {},
    membershipCreditBalance: null
  }
};

const financialAccountReducer = (state = DEFAULT_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case `${GET_TRANSACTIONS}_ACK`: {
      let transactions = [...state.transactions]; // mutating the existing array will confuse react, so create a new array
      payload.forEach((t) => {
        if (
          !state.transactions.find((element, index, array) => {
            return t.id == element.id;
          })
        ) {
          transactions.push(t);
        }
      });

      return {
        ...state,
        transactions: transactions
      };
    }
    case `${GET_PENDING_TRANSACTIONS}_ACK`:
      return {
        ...state,
        pendingTransactions: payload
      };
    case `${USER_GET_ACCOUNT_STATEMENTS}_ACK`:
      return {
        ...state,
        statements: payload?.statements
        // statementsToken: payload?.token,
        // statementsTokenExpiresAt: payload?.expires_at
      };
    case `${GET_ACCOUNT_STATS}_ACK`:
      return {
        ...state,
        accountStats: {
          topCategories: payload.categories,
          weeklySpend: payload.weekly_spend,
          totalInterestEarned: payload.total_interest_earned,
          currentMonth: payload.current_month,
          lastMonth: payload.last_month,
          rewards: payload.rewards
        }
      };
    case `${USER_REDEEM_CASHBACK_POINTS}_ACK`:
      return {
        ...state,
        accountStats: {
          ...state.accountStats,
          rewards: payload.rewards
        }
      };
    case `${GET_REFERRAL_STATS}_ACK`:
      return {
        ...state,
        referralStats: {
          incompleteReferralCount: payload.incomplete_referrals,
          completedReferrals: payload.completed_referrals,
          membershipCreditBalance: payload.balance
        }
      };
    case `${GET_RECURRING_TRANSACTIONS}_ACK`:
      return {
        ...state,
        recurringTransactions: payload
      };
    case `${UPDATE_RECURRING_TRANSACTION}_ACK`:
      return {
        ...state,
        recurringTransactions: payload
      };
    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        transactions: [],
        pendingTransactions: []
      };
    default:
      return state;
  }
};

export default financialAccountReducer;
