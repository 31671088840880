import { BASE_URL, UNIT_BASE_URL } from './constants';

export const phoneVerificationStart = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/unit/verification/start`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const phoneVerificationFinish = async ({
  token,
  verificationId,
  verificationCode,
  scope
}) => {
  const response = await fetch(`${BASE_URL}/unit/verification/finish`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      verificationId,
      verificationCode,
      scope
    })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const createBankTransfer = async ({
  token,
  counterpartyId,
  depositAccountId,
  amount,
  isCredit,
  description,
  scheduledDate,
  selectedFrequency,
  // dayOfWeek,
  // dayOfMonth,
  endDate,
  totalTransfers,
  isPreview
}) => {
  const response = await fetch(`${BASE_URL}/unit/bank_transfers`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      counterparty_id: counterpartyId,
      deposit_account_id: depositAccountId,
      amount: amount,
      is_credit: isCredit,
      description: description,
      is_preview: isPreview,
      scheduled_date: scheduledDate,
      selected_frequency: selectedFrequency,
      // day_of_week: dayOfWeek,
      // day_of_month: dayOfMonth,
      end_date: endDate,
      total_transfers: totalTransfers
    })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getPinStatus = async ({ accountId, unitToken }) => {
  const response = await fetch(
    `${UNIT_BASE_URL}cards/${accountId}/secure-data/pin/status`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${unitToken}`,
        'Content-Type': 'application/json'
      }
    }
  );
  const jsonResponse = await response.json();
  return jsonResponse;
};

// returns a PDF blob
export const viewAccountStatement = async ({ unitToken, statementId }) => {
  const response = await fetch(
    `${UNIT_BASE_URL}statements/${statementId}/pdf`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${unitToken}`
      }
    }
  );
  return response;
};
