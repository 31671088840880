import './logger';
import './polyfills';
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import amplitude from 'amplitude-js';
import { createPopper } from '@popperjs/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Hotjar from '@hotjar/browser';

import { REACT_ENV, STRIPE_PUBLIC_KEY } from './api/constants';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

if (REACT_ENV === 'production') {
  amplitude.getInstance().init('ed668f335761294402cc98e116f3cf6a');

  // LogRocket.init('ouxwml/sequin');
  // Sentry.init({
  //   dsn: 'https://e9c0a7b6b02f4d2b8e973dfa3dcaa5ca@o507651.ingest.sentry.io/5599054',
  //   autoSessionTracking: true,
  //   integrations: [new Integrations.BrowserTracing()],
  //   environment: REACT_ENV,
  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0
  // });
} else {
  amplitude.getInstance().init('ae8b8e53d0aa17ee9988e8ee676d35be');
}

// stripe elements : to do : parameterize
export const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

// Required to enable animations on dropdowns/tooltips/popovers
createPopper.Defaults = {
  modifiers: [
    {
      name: 'computeStyle',
      options: {
        gpuAcceleration: false
      }
    }
  ]
};

// hotjar
const siteId = 5030283;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const store = configureStore(window.__INITIAL_STATE__);

const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
