import { authenticated } from './common';
import api from '../../api';

export const CARD_RECORD_CARDHOLDER = 'CARD_RECORD_CARDHOLDER';
export const CARD_FETCH = 'CARD_FETCH';
export const GET_SEQUIN_CARDS = 'GET_SEQUIN_CARDS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_PENDING_TRANSACTIONS = 'GET_PENDING_TRANSACTIONS';
export const FUND_CARD = 'FUND_CARD';
export const POST_TOKEN = 'POST_TOKEN';
export const TOGGLE_FREEZE_CARD = 'TOGGLE_FREEZE_CARD';
export const CLOSE_CARD = 'CLOSE_CARD';
export const GET_ACCOUNT_STATS = 'GET_ACCOUNT_STATS';
export const CREATE_CARD = 'CREATE_CARD'; // no reducer
export const GET_ACTIVE_AGREEMENTS = 'GET_ACTIVE_AGREEMENTS'; // no reducer
export const GET_RECURRING_TRANSACTIONS = 'GET_RECURRING_TRANSACTIONS';
export const UPDATE_RECURRING_TRANSACTION = 'UPDATE_RECURRING_TRANSACTION';
export const GET_REFERRAL_STATS = 'GET_REFERRAL_STATS';

export const UPDATE_EMBOSSED_LINE = 'UPDATE_EMBOSSED_LINE';
export const SELECT_FREE_PRODUCT = 'SELECT_FREE_PRODUCT';
export const SELECT_NON_FINANCIAL_PRODUCT = 'SELECT_NON_FINANCIAL_PRODUCT';
export const OPEN_FINANCIAL_PRODUCT = 'OPEN_FINANCIAL_PRODUCT';

export const USER_REDEEM_CASHBACK_POINTS = 'USER_REDEEM_CASHBACK_POINTS';

export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';

export function recordCardholder({
  cardholderId,
  ssn,
  dob,
  address1,
  address2,
  city,
  state,
  zip,
  country_code,
  occupation,
  cpId
}) {
  return authenticated({
    type: CARD_RECORD_CARDHOLDER,
    func: api.card.recordCardholder,
    params: {
      cardholderId,
      ssn,
      dob,
      address1,
      address2,
      city,
      state,
      zip,
      country_code,
      occupation,
      cpId
    }
  });
}

export function fetchCards({ cardholderId }) {
  return authenticated({
    type: CARD_FETCH,
    func: api.card.fetchCards,
    params: { cardholderId }
  });
}

export function getSequinCards() {
  return authenticated({
    type: GET_SEQUIN_CARDS,
    func: api.card.getCards,
    params: {}
  });
}

export function toggleFreezeCard({ cardId }) {
  return authenticated({
    type: TOGGLE_FREEZE_CARD,
    func: api.card.toggleFreezeCard,
    params: { cardId }
  });
}

export function replaceCard({ cardId, address, reason, embossLine }) {
  return authenticated({
    type: CLOSE_CARD,
    func: api.card.replaceCard,
    params: { cardId, address, reason, embossLine }
  });
}

export function getTransactions({
  offset,
  limit,
  includeTransactions,
  includePayments
}) {
  return authenticated({
    type: GET_TRANSACTIONS,
    func: api.card.getTransactions,
    params: {
      offset,
      limit,
      includeTransactions,
      includePayments
    }
  });
}

export function getPendingTransactions() {
  return authenticated({
    type: GET_PENDING_TRANSACTIONS,
    func: api.card.getPendingTransactions,
    params: {}
  });
}

export function postToken({ aptoToken }) {
  return authenticated({
    type: POST_TOKEN,
    func: api.card.postToken,
    params: { aptoToken }
  });
}

export function getAccountStats() {
  return authenticated({
    type: GET_ACCOUNT_STATS,
    func: api.card.getAccountStats,
    params: {}
  });
}

export function getReferralStats() {
  return authenticated({
    type: GET_REFERRAL_STATS,
    func: api.card.getReferralStats,
    params: {}
  });
}

export function createCard({ address, physical, embossLine }) {
  return authenticated({
    type: CREATE_CARD,
    func: api.card.createCard,
    params: { address, physical, embossLine }
  });
}

export function getActiveAgreements() {
  return authenticated({
    type: GET_ACTIVE_AGREEMENTS,
    func: api.card.getActiveAgreements,
    params: {}
  });
}

export function getRecurringTransactions() {
  return authenticated({
    type: GET_RECURRING_TRANSACTIONS,
    func: api.card.getRecurringTransactions,
    params: {}
  });
}

export function updateRecurringTransaction({ id, enabled }) {
  return authenticated({
    type: UPDATE_RECURRING_TRANSACTION,
    func: api.card.updateRecurringTransaction,
    params: { id, enabled }
  });
}

export function updateCardEmbossLine({ embossLine }) {
  return authenticated({
    type: UPDATE_EMBOSSED_LINE,
    func: api.card.updateEmbossLine,
    params: { embossLine }
  });
}

export function selectFreeProduct() {
  return authenticated({
    type: SELECT_FREE_PRODUCT,
    func: api.card.selectFreeProduct,
    params: {}
  });
}

export function selectNonFinancialProduct() {
  return authenticated({
    type: SELECT_NON_FINANCIAL_PRODUCT,
    func: api.card.selectNonFinancialProduct,
    params: {}
  });
}

export function openFinancialProduct() {
  return authenticated({
    type: OPEN_FINANCIAL_PRODUCT,
    func: api.card.openFinancialProduct,
    params: {}
  });
}

export function redeemCashbackPoints({ points }) {
  return authenticated({
    type: USER_REDEEM_CASHBACK_POINTS,
    func: api.card.redeemCashbackPoints,
    params: { points }
  });
}

export const clearTransactions = () => ({
  type: CLEAR_TRANSACTIONS
});
