import { USER_GET_REFERRER } from '../actions/user.actions';

export const DEFAULT_STATE = {
  first_name: undefined,
  product_type: undefined
};

const referrerReducer = (state = DEFAULT_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case `${USER_GET_REFERRER}_ACK`: {
      return {
        ...state,
        first_name: payload.first_name,
        product_type: payload.product_type
      };
    }
    default:
      return state;
  }
};

export default referrerReducer;
