import { combineReducers } from 'redux';
import contentful, {
  DEFAULT_STATE as CONTENTFUL_DEFAULT_STATE
} from './contentful';
import financialAccount, {
  DEFAULT_STATE as FINANCIAL_ACCOUNT_DEFAULT_STATE
} from './financialAccount';
import plaid, { DEFAULT_STATE as PLAID_DEFAULT_STATE } from './plaid';
import referrer, { DEFAULT_STATE as REFERRER_DEFAULT_STATE } from './referrer';
import user, { DEFAULT_STATE as USER_DEFAULT_STATE } from './user';
import { USER_LOGOUT } from '../actions/user.actions';

export const DEFAULT_STATE = {
  plaid: PLAID_DEFAULT_STATE,
  user: USER_DEFAULT_STATE,
  financialAccount: FINANCIAL_ACCOUNT_DEFAULT_STATE,
  referrer: REFERRER_DEFAULT_STATE,
  contentful: CONTENTFUL_DEFAULT_STATE
};

const appReducer = combineReducers({
  plaid,
  user,
  financialAccount,
  referrer,
  contentful
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = { ...DEFAULT_STATE };
  }
  return appReducer(state, action);
};

export default rootReducer;
