/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as CONSTANTS from "./constants";

export const formatAccountInfo = (account) => {
  return (account.accountName || account.account_name) + ' ••••' + account.mask;
};

export const mapFinancials = (financials) => {
  return financials?.length
    ? financials.map((bankAccount) => ({
      accountId: bankAccount.account_id,
      accountName: bankAccount.account_name,
      currentBalance: bankAccount.current_balance,
      isOverdue: bankAccount.is_overdue,
      institutionName: bankAccount.institution_name,
      institutionId: bankAccount.institution_id,
      lastPaymentAmount: bankAccount.last_payment_amount,
      lastPaymentDate: bankAccount.last_payment_date,
      lastStatementBalance: bankAccount.last_statement_balance,
      lastStatementIssueDate: bankAccount.last_statement_issue_date,
      limit: bankAccount.limit,
      mask: bankAccount.mask,
      minimumPaymentAmount: bankAccount.minimum_payment_amount,
      nextPaymentDueDate: bankAccount.next_payment_due_date,
      recommendedPayoff: bankAccount.recommended_payoff,
      updatedAt: bankAccount.updated_at,
      utilization: bankAccount.utilization,
      //prevUtilization: bankAccount.prev_utilization,
      isPayoff: bankAccount.is_payoff,
      //delta: bankAccount.delta,
      plaidAuthId: bankAccount.plaid_auth_id,
      requiresPlaidUpdate: bankAccount.requires_plaid_update,
      requiresUserLimit: bankAccount.requires_user_limit,
      requiresUserPaymentDueDate: bankAccount.requires_user_payment_due_date,
      userAccountId: bankAccount.user_account_id
    }))
    : [];
};

export const formatInteger = (value) => {
  return parseInt(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}

export const formatCurrency = (
  amount,
  currency = 'USD',
  noMinorUnits = false
) => {
  if (amount === null || amount === undefined) {
    return '-';
  }
  let amount_str = parseFloat(amount).toLocaleString('en-US', {
    minimumFractionDigits: noMinorUnits ? 0 : 2,
    maximumFractionDigits: noMinorUnits ? 0 : 2,
    currency: currency,
    style: 'currency'
  });
  return amount_str;
};

export const formatInterestRate = (rate) => {
  if (rate === null || rate === undefined) {
    return '-';
  }
  let rate_str = (parseFloat(rate) / 100.0).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent'
  });
  return rate_str;
};

export const formatDate = (datestr) => {
  const d = new Date(datestr);
  return (
    d.toLocaleString('default', { month: 'long', timeZone: 'UTC' }) +
    ' ' +
    d.getUTCDate()
  );
};

export const formatDateWithoutTZ = (datestr) => {
  return new Date(datestr).toLocaleString('default', {
    dateStyle: 'long',
    timeZone: 'UTC'
  });
};

// convert to browser's local timezone
export const formatLocalDate = (datestr) => {
  const d = new Date(datestr);
  return d.toLocaleString('default', { dateStyle: 'long' });
};

export const formatDateTime = (datestr) => {
  const d = new Date(datestr);
  return d.toLocaleTimeString('default', { month: 'long', day: 'numeric' });
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const displayPhone = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }

  const stripped = phoneNumber.replace(/\D/g, '');

  return `(***)-***-${stripped.slice(7, 11)}`;
};

export const sanitizePhone = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }

  let stripped = phoneNumber.replace(/\D/g, '');

  if (stripped.length > 10 && stripped.slice(0, 1) === '1') {
    stripped = stripped.slice(1, 11);
  }

  return stripped;
};

// determine where to resume the onboarding
export const getNextCardApplicationPage = (user) => {
  let details = user?.details;
  let path = '/';
  let cardProduct = user.cardProduct;

  if (!details?.id) {
    // not logged in
    path = '/apply';
  } else if (!user.cardProduct) {
    path = '/apply';
  } else if (user.cardProduct?.onboarding_finished_at) {
    path = '/card/dashboard';
  } else if (user.cardProduct?.onboarding_rejected_at) {
    path = '/apply/application-failed';
  } else {
    let accountStatus = details.accountStatus;

    // handle multiple product flows
    if (accountStatus.agreements !== 'active') {
      path = '/apply/complete-agreements';
    } else if (cardProduct?.is_initial) {
      path = '/apply/membership';
    } else if (cardProduct?.is_non_financial) {
      path = '/card/dashboard';
    } else if (!cardProduct?.is_paid) {
      if (accountStatus.unit_application === 'denied') {
        path = '/apply/wait-for-application';
      } else if (accountStatus.unit_application === 'incomplete') {
        path = '/apply/wait-for-application';
      } else if (accountStatus.unit_application !== 'active') {
        path = '/apply/review-agreements';
      } else if (
        !accountStatus ||
        (accountStatus.plaid !== 'active' && accountStatus.plaid !== 'skipped')
      ) {
        path = '/apply/link-bank-account';
      } else {
        path = '/apply/setup-pending';
      }
    } else if (cardProduct?.is_paid) {
      if (accountStatus.membership !== 'active') {
        path = '/apply/membership';
      } else if (accountStatus.unit_application === 'denied') {
        path = '/apply/wait-for-application';
      } else if (accountStatus.unit_application === 'incomplete') {
        path = '/apply/wait-for-application';
      } else if (accountStatus.unit_application !== 'active') {
        path = '/apply/review-agreements';
      } else if (
        !accountStatus ||
        (accountStatus.plaid !== 'active' && accountStatus.plaid !== 'skipped')
      ) {
        path = '/apply/link-bank-account';
      } else {
        path = '/apply/setup-pending';
      }
    }
  }

  //console.log(`getNextCardApplicationPage: ${path} (from ${window.location.pathname})`);
  return path;
};

const nextPageMap = {
  '/apply/welcome': '/apply/how-it-works-1',
  '/apply/how-it-works-1': '/apply/how-it-works-2',
  '/apply/how-it-works-2': '/apply/how-it-works-3',
  '/apply/how-it-works-3': '/apply/register',
  '/apply/register': '/apply/verify-phone',
  '/apply/verify-phone': '/apply/complete-agreements',
  '/apply/complete-agreements': '/apply/membership?skip=y',
  '/apply/before-moving-on': '/apply/helpful-tips',
  '/apply/helpful-tips': '/apply/collect-application-details',
  '/apply/membership': '/apply/select-payment-plan',
  '/apply/select-payment-plan': '/apply/confirm-membership',
  '/apply/confirm-membership': '/apply/membership-complete',
  '/apply/membership-complete': '/apply/start-application',
  '/apply/start-application': '/apply/collect-application-details',
  '/apply/collect-application-details': '/apply/wait-for-application',
  '/apply/wait-for-application': '/apply/link-bank-account',
  '/apply/link-bank-account': '/apply/setup-pending',
  '/apply/setup-pending': '/apply/fund-account',
  '/apply/customize-card': '/apply/fund-account',
  '/apply/fund-account': '/apply/order-card',
  '/apply/order-card': '/apply/complete-welcome',
  '/apply/complete-welcome': '/apply/complete-direct-deposit',
  '/apply/complete-direct-deposit': '/apply/complete-invite',
  '/apply/complete-invite': '/apply/how-did-you-hear-about-us',
  '/apply/how-did-you-hear-about-us': '/card/dashboard',
  '/apply/welcome-back': '/apply/complete-agreements', // returning users
  '/apply/high-yield-checking': '/apply/collect-application-details', // free tier
  '/apply/review-agreements': '/apply/start-application', // re-apply flow
  '/apply/pending': '/apply/link-bank-account'
};

export const nextPage = ({ pathname, query } = {}) => {
  const target = `${nextPageMap[pathname || window.location.pathname]}${query || ''
    }`;
  console.log(
    `transitioning to ${target} from ${pathname || window.location.pathname}`
  );
  return target;
};

export const userAuthUrl = () => {
  return '/apply/verify-phone?returning=true';
};

// map internal states to external labels. Note: some of these are counterintuitive
export const mapSettlementStates = (state) => {
  switch (state) {
    case 'failed':
      return 'failed';
    case 'cancelled':
      return 'canceled';
    case 'complete':
      return 'submitted';
    case 'offset':
      return 'complete';
    case 'returned':
      return 'returned';
    case 'cleared':
      return 'complete';
    case 'cleared_frozen':
      return 'complete';
    case 'gather':
      return 'pending';
    default:
      return 'pending';
  }
};

export const simplePlural = (value, plural = 's') => {
  return value === 1 ? '' : plural;
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    // cross-origin iframe
    return null;
  }
};

export const hasAuthToken = () => {
  const token = window.localStorage.getItem('unit_token_base');

  if (token) {
    let json_token = JSON.parse(token);
    if (
      json_token.expires_at &&
      Date.parse(json_token.expires_at) > Date.now()
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const replaceConstants = (content) => {
  let replaced_content = content
  for (var key in CONSTANTS) {
    replaced_content = replaced_content.replace(`{${key}}`, CONSTANTS[key]);
  }
  return replaced_content
}
