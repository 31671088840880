import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BsTools } from 'react-icons/bs';
import {
  IoBookOutline,
  IoCardOutline,
  IoCashOutline,
  IoHelpCircleOutline,
  IoLogOutOutline,
  IoOptions
} from 'react-icons/io5';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Logo from './logo';
import { getNextCardApplicationPage } from './utils';
import { ReactComponent as SequinIcon } from '../assets/images/logo-s.svg';
import { logout } from '../store/actions/user.actions';
import './layouts/SequinLayout.scss';
import { Link } from 'react-router-dom';

export const Sidebar = ({ onLogout, user, isCollapsed, setIsCollapsed }) => {
  let navigate = useNavigate();
  const [initialCollapsed, setInitialCollapsed] = React.useState(isCollapsed);
  const isFree = user?.cardProduct?.is_paid === false;
  const isNonFinancial = user?.cardProduct?.is_non_financial === true;

  const logoutUser = () => {
    onLogout();
    navigate('/');
  };

  const mouseEnter = () => {
    setIsCollapsed(false);
  };

  const mouseLeave = () => {
    setIsCollapsed(initialCollapsed);
  };

  return (
    <div
      className={`sequin-side-nav ${isCollapsed ? 'collapsed' : ''}`}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <div className="side-nav-top">
        <div className="block">
          {isCollapsed ? (
            <div className="logo-wrapper">
              <SequinIcon fill="#60034c" height="24px" />
            </div>
          ) : (
            <div className="logo-wrapper">
              <Logo color="#60034c" linked={false} />
            </div>
          )}
        </div>
        {window.location.pathname !== '/home' && (
          <NavLink
            to={getNextCardApplicationPage(user)}
            end
            className="nav-item"
          >
            <IoCardOutline size={22} />
            {!isCollapsed && ' Dashboard'}
          </NavLink>
        )}

        <NavLink to="/financial-tools" end className="nav-item">
          <BsTools size={20} />
          {!isCollapsed && ' Tools'}
        </NavLink>

        {!isNonFinancial && (
          <NavLink to="/rewards" className="nav-item">
            <FontAwesomeIcon icon={['fax', 'cashback']} size="lg" />
            {!isCollapsed && ' Rewards'}
          </NavLink>
        )}

        <NavLink to="/card/dashboard/university" className="nav-item">
          <IoBookOutline size={20} />
          {!isCollapsed && ' University'}
        </NavLink>

        <NavLink to="/card/dashboard/perks" className="nav-item">
          <FontAwesomeIcon icon={['fax', 'gift']} size="lg" />
          {!isCollapsed && ' Perks'}
        </NavLink>

        {isFree && !isCollapsed && (
          <Link to="/become-a-member" className="upgrade">
            <div className="sidebar-upgrade-cta">
              Access exclusive financial education workshops!
              <br />
              <br />
              <u>Upgrade to join today</u>
            </div>
          </Link>
        )}
      </div>
      <div className="side-nav-bottom">
        {isFree ? (
          <NavLink to="/become-a-member" end className="nav-item upgrade">
            <IoCashOutline size={22} />
            {!isCollapsed && ' Upgrade Your Plan'}
          </NavLink>
        ) : (
          <NavLink to="/card/refer" end className="nav-item referral">
            <IoCashOutline size={22} />
            {!isCollapsed && ' Get 1 Month Free'}
          </NavLink>
        )}
        <NavLink to="/card/options" end className="nav-item">
          <IoOptions size={22} />
          {!isCollapsed && ' Account'}
        </NavLink>
        <NavLink to="/faq" end className="nav-item">
          <IoHelpCircleOutline size={22} />
          {!isCollapsed && ' FAQ'}
        </NavLink>
        <NavLink to="/logout" end className="nav-item">
          <IoLogOutOutline size={22} />
          {!isCollapsed && ' Logout'}
        </NavLink>
      </div>
    </div >
  );
};

export default connect((state) => ({ user: state.user }), { onLogout: logout })(
  Sidebar
);
