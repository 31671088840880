import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

const RedirectComponent = ({ to, state }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, { state });
  }, [to, navigate]);

  return null;
};

export default RedirectComponent;
