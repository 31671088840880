import React, { useRef } from 'react';

import amplitude from 'amplitude-js';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

import LayoutBlank from './layouts/LayoutBlank';
import { LoadingSpinner } from './LoadingSpinner';
import NotFound from './NotFound';
import { DefaultLayout, routes } from '../routes';
import {
  autoLogin,
  logout,
  sessionKeepAlive
} from '../store/actions/user.actions';

const mapStateToProps = ({ user }) => ({
  isUserLoggedIn: !!user.details?.id
});

const displayRoutes = (routes, isUserLoggedIn, onUserAction) => {
  return routes.map((route) => {
    const Layout =
      route.layout || (route.requiresLogin ? DefaultLayout : LayoutBlank);

    if (
      route.requiresLogin === undefined ||
      route.requiresLogin === isUserLoggedIn
    ) {
      let Element = () => {
        const params = useParams();

        React.useEffect(() => {
          window.scrollTo(0, 0);
        });

        return (
          <div
            onClick={() => onUserAction(route.sessionTimeout)}
            onMouseMove={() => onUserAction(route.sessionTimeout)}
            onKeyUp={() => onUserAction(route.sessionTimeout)}
            tabIndex={0}
          >
            <Layout {...params} routeConfig={route.config}>
              <route.component {...params} />
            </Layout>
          </div>
        );
      };

      return <Route key={route.path} path={route.path} element={<Element />} />;
    } else {
      let Element = () => {
        const navigate = useNavigate();
        const location = useLocation();
        React.useEffect(() => {
          navigate('/', {
            state: {
              search: location.search,
              from: location
            }
          });
        }, []);

        return null;
        // return (
        //   <Navigate
        //     to='/'
        //     state={{
        //       search: location.search,
        //       from: location
        //     }}
        //   />
        // )
      };

      return <Route key={route.path} path={route.path} element={<Element />} />;
    }
  });
};

const Router = ({
  onAutoLogin,
  onLogout,
  onSessionKeepAlive,
  isUserLoggedIn
}) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const inactivityTimeout = useRef(null);

  document.documentElement.classList.add('app-loading');

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    //console.log("Debug: in Router.useEffect[location]")

    const token = window.localStorage.getItem('sequin_token');
    onAutoLogin({ token }).finally((res) => {
      document.documentElement.classList.remove('app-loading');
      setIsLoading(false);
    });

    amplitude &&
      amplitude
        .getInstance()
        .logEvent('Page View', { page: location.pathname });
  }, [location]);

  React.useEffect(() => {
    //console.log("Debug: in Router.useEffect[isUserLoggedIn]")
    resetTimeout();

    return () => clearTimeout(inactivityTimeout.current);
  }, [isUserLoggedIn]);

  // Login timeout
  const onUserAction = (sessionTimeout) => {
    resetTimeout(sessionTimeout);
  };

  const expireLogout = () => {
    navigate('/logout', { state: { expired: true } });

    // onLogout().then(() => {
    //   //console.log('expireLogout: navigating to /session-expired');
    //   navigate('/session-expired');
    // })
  };

  const resetTimeout = (sessionTimeout) => {
    if (!isUserLoggedIn) {
      return;
    }

    let timeout = sessionTimeout || 10 * 60 * 1000;
    if (process.env.NODE_ENV !== 'production') {
      timeout = 175 * 60 * 1000;
    }

    //console.log(`RESET TIMEOUT: ${timeout}`);

    //console.log('Debug: resetting inactivity timeout ' + inactivityTimeout.current);

    clearTimeout(inactivityTimeout.current);
    keepSessionAlive(timeout);
    let inactivityTimeoutId = setTimeout(() => {
      expireLogout();

      // Log out the user by calling the API to end their session
      // and redirect the user to the login page
    }, timeout); // 10 minutes

    //console.log(`debug: new timeout set: ${id} / ${inactivityTimeoutId}`)

    inactivityTimeout.current = inactivityTimeoutId;
  };

  const keepSessionAlive = throttle(
    (timeout) => {
      onSessionKeepAlive({ timeout })
        .then((res) => {
          if (!res.value.token) {
            expireLogout();
          }
        })
        .catch((error) => {
          expireLogout();
        });
    },
    60 * 1000,
    { trailing: true }
  ); // 1 minute

  //console.log("Debug: in Router render")

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      {displayRoutes(routes, isUserLoggedIn, onUserAction)}
      <Route path="*" component={NotFound} />
    </Routes>
  );
};

Router.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  onAutoLogin: PropTypes.func
};

export default connect(mapStateToProps, {
  onAutoLogin: autoLogin,
  onLogout: logout,
  onSessionKeepAlive: sessionKeepAlive
})(Router);
