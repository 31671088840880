import * as React from 'react';

import PropTypes from 'prop-types';

export const Logo = ({ color = '#60034c', linked = true }) => {
  // const image = (
  //   <svg width="123" height="24" viewBox="0 0 93 20" fill="none">
  //     <g clipPath="url(#clip0)">
  //       <path
  //         d="M16.7466 0.887207V19.5351H28.7325V17.3097H19.3381V11.3097H27.4085V9.08439H19.3381V3.11256H28.7325V0.887207H16.7466Z"
  //         fill={color}
  //       />
  //       <path
  //         d="M61.2255 11.9717C61.2255 15.6478 60.0987 17.5773 56.3241 17.5773C52.5495 17.5773 51.479 15.6478 51.479 11.9717V0.887207H48.9861V12.6055C48.9861 17.2534 51.3241 19.8027 56.3241 19.8027C61.3523 19.8027 63.7044 17.2675 63.7044 12.6055V0.887207H61.2114V11.9717H61.2255Z"
  //         fill={color}
  //       />
  //       <path
  //         d="M65.1548 0.887207V3.11256H69.2111V17.3097H65.1548V19.5351H75.7463V17.3097H71.69V3.11256H75.7463V0.887207H65.1548Z"
  //         fill={color}
  //       />
  //       <path
  //         d="M89.9718 0.887207V17.0703L90 18.4647H89.845L82.3098 0.887207H77.676V19.5351H79.9859V3.33791L79.9436 1.95763H80.0986L87.7464 19.5351H92.3943V0.887207H89.9718Z"
  //         fill={color}
  //       />
  //       <path
  //         d="M8.19718 8.91537L7.91549 8.84495C5.16901 8.15481 3.46478 7.36608 3.46478 5.59143C3.46478 4.64777 3.84507 2.46467 7.39436 2.46467C9.94366 2.46467 11.2817 3.6196 11.5915 6.11256V6.15481H14.2817V6.11256C14.0704 2.40833 11.5634 0.295654 7.40845 0.295654C5.21126 0.295654 3.28169 0.999879 2.09859 2.2534C1.19719 3.19706 0.76056 4.39425 0.788729 5.78861C0.873236 9.39425 4.35211 10.2957 7.42254 11.0844C9.84507 11.7182 12.1408 12.3238 12.1408 14.5914C12.1408 16.8731 9.8169 17.69 7.64788 17.69C4.78873 17.69 2.74647 15.7323 2.69014 12.9295V12.8872H0V12.9295C0.0985915 17.1407 3.09859 19.859 7.64788 19.859C10.9577 19.859 14.831 18.4647 14.831 14.5069C14.8028 10.5773 11.4507 9.73227 8.19718 8.91537Z"
  //         fill={color}
  //       />
  //       <path
  //         d="M44.6761 16.9155C46.3804 15.1127 47.324 12.5634 47.324 9.70423C47.324 4.88733 44.3663 0 38.7184 0C33.0705 0 30.1128 4.87324 30.1128 9.70423C30.1128 14.5211 33.0705 19.4084 38.7184 19.4084C40.3522 19.4084 41.8592 18.9859 43.1128 18.1972C43.7606 18.7465 45.4226 19.9296 46.1973 20H46.2536L47.1128 18.169L47.0142 18.1408C46.2677 17.9437 45.4085 17.5493 44.6761 16.9155ZM43.0142 15.2817C41.9297 14.3521 40.8592 13.3099 40.1973 13.0282L40.1269 13L39.1128 14.6901L39.1973 14.7324C40.2677 15.2535 40.7747 16 41.3663 16.5352C40.5635 16.9437 39.6762 17.1549 38.7184 17.1549C34.6198 17.1549 32.479 13.4084 32.479 9.69014C32.479 5.98591 34.6198 2.22535 38.7184 2.22535C42.817 2.22535 44.9578 5.97183 44.9578 9.69014C44.9578 11.8873 44.2536 13.9155 43.0142 15.2817Z"
  //         fill={color}
  //       />
  //     </g>
  //     <defs>
  //       <clipPath id="clip0">
  //         <rect width="92.3944" height="20" fill="white" />
  //       </clipPath>
  //     </defs>
  //   </svg>
  // );

  const image = (
    <svg
      width="124"
      height="24"
      viewBox="-20 0 114 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16.7466 0.887207V19.5351H28.7325V17.3097H19.3381V11.3097H27.4085V9.08439H19.3381V3.11256H28.7325V0.887207H16.7466Z"
          fill={color}
        />
        <path
          d="M61.2255 11.9717C61.2255 15.6478 60.0987 17.5773 56.3241 17.5773C52.5495 17.5773 51.479 15.6478 51.479 11.9717V0.887207H48.9861V12.6055C48.9861 17.2534 51.3241 19.8027 56.3241 19.8027C61.3523 19.8027 63.7044 17.2675 63.7044 12.6055V0.887207H61.2114V11.9717H61.2255Z"
          fill={color}
        />
        <path
          d="M65.1548 0.887207V3.11256H69.2111V17.3097H65.1548V19.5351H75.7463V17.3097H71.69V3.11256H75.7463V0.887207H65.1548Z"
          fill={color}
        />
        <path
          d="M89.9718 0.887207V17.0703L90 18.4647H89.845L82.3098 0.887207H77.676V19.5351H79.9859V3.33791L79.9436 1.95763H80.0986L87.7464 19.5351H92.3943V0.887207H89.9718Z"
          fill={color}
        />
        <path
          d="M8.19718 8.91537L7.91549 8.84495C5.16901 8.15481 3.46478 7.36608 3.46478 5.59143C3.46478 4.64777 3.84507 2.46467 7.39436 2.46467C9.94366 2.46467 11.2817 3.6196 11.5915 6.11256V6.15481H14.2817V6.11256C14.0704 2.40833 11.5634 0.295654 7.40845 0.295654C5.21126 0.295654 3.28169 0.999879 2.09859 2.2534C1.19719 3.19706 0.76056 4.39425 0.788729 5.78861C0.873236 9.39425 4.35211 10.2957 7.42254 11.0844C9.84507 11.7182 12.1408 12.3238 12.1408 14.5914C12.1408 16.8731 9.8169 17.69 7.64788 17.69C4.78873 17.69 2.74647 15.7323 2.69014 12.9295V12.8872H0V12.9295C0.0985915 17.1407 3.09859 19.859 7.64788 19.859C10.9577 19.859 14.831 18.4647 14.831 14.5069C14.8028 10.5773 11.4507 9.73227 8.19718 8.91537Z"
          fill={color}
        />
        <path
          d="M44.6761 16.9155C46.3804 15.1127 47.324 12.5634 47.324 9.70423C47.324 4.88733 44.3663 0 38.7184 0C33.0705 0 30.1128 4.87324 30.1128 9.70423C30.1128 14.5211 33.0705 19.4084 38.7184 19.4084C40.3522 19.4084 41.8592 18.9859 43.1128 18.1972C43.7606 18.7465 45.4226 19.9296 46.1973 20H46.2536L47.1128 18.169L47.0142 18.1408C46.2677 17.9437 45.4085 17.5493 44.6761 16.9155ZM43.0142 15.2817C41.9297 14.3521 40.8592 13.3099 40.1973 13.0282L40.1269 13L39.1128 14.6901L39.1973 14.7324C40.2677 15.2535 40.7747 16 41.3663 16.5352C40.5635 16.9437 39.6762 17.1549 38.7184 17.1549C34.6198 17.1549 32.479 13.4084 32.479 9.69014C32.479 5.98591 34.6198 2.22535 38.7184 2.22535C42.817 2.22535 44.9578 5.97183 44.9578 9.69014C44.9578 11.8873 44.2536 13.9155 43.0142 15.2817Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="124" height="24" fill="white" />
        </clipPath>
      </defs>
      <path
        d="M -2.715 0.3 L -12.962 0.3 C -16.914 0.3 -19 1.398 -19 5.181 C -19 8.965 -16.914 10.1 -12.962 10.1 L -19 10.1 L -19 19.9 L -8.754 19.9 C -4.802 19.9 -2.715 18.803 -2.715 15.019 C -2.715 11.235 -4.802 10.1 -8.754 10.1 L -2.715 10.1 L -2.715 0.3 Z"
        fill={color}
      />
    </svg>
  );

  if (linked) {
    return <a href="https://www.sequincard.com">{image}</a>;
  } else {
    return image;
  }
};

Logo.propTypes = {
  color: PropTypes.string,
  linked: PropTypes.bool
};

export default Logo;
