import React, { Component } from 'react';

import MediaQuery from 'react-responsive';

import Bottombar from '../Bottombar';
import Sidebar from '../Sidebar';

import './SequinLayout.scss';

export const MainLayout = ({ routeConfig, children }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(
    routeConfig?.isCollapsed
  );

  // React.useEffect(() => {
  //   if (routeConfig?.isCollapsed) {
  //     setTimeout(() => {
  //       setIsCollapsed(routeConfig.isCollapsed);
  //     }, 200)
  //   }
  // })

  return (
    <div className="main-layout-wrapper">
      <MediaQuery minWidth={1225}>
        <div className="sq-layout-container">
          <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          <div
            className={`sq-layout-content with-sidebar ${isCollapsed ? 'collapsed' : ''
              }`}
          >
            {children}
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div className="sq-layout-container">
          {children}
          <Bottombar />
        </div>
      </MediaQuery>
    </div>
  );
};
// class MainLayout extends Component {
//   render() {
//     return (
//       <div className="main-layout-wrapper">
//         <MediaQuery minWidth={1225}>
//           <div className="sq-layout-container">
//             <Sidebar {...this.props} />
//             <div className="sq-layout-content with-sidebar">
//               {this.props.children}
//             </div>
//           </div>
//         </MediaQuery>
//         <MediaQuery maxWidth={1224}>
//           <div className="sq-layout-container">
//             {this.props.children}
//             <Bottombar {...this.props} />
//           </div>
//         </MediaQuery>
//       </div>
//     );
//   }
// }

export default MainLayout;
