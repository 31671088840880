import { CONTENTFUL_GET_CONTENT } from "../actions/contentful.actions";


export const DEFAULT_STATE = {
};

const contentfulReducer = (state = DEFAULT_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case `${CONTENTFUL_GET_CONTENT}_ACK`: {
      let data = {};
      data[payload.key] = payload.content

      return {
        ...state,
        ...data
      };
    }
    default:
      return state;
  }
};

export default contentfulReducer;
