import { authenticated } from './common';
import api from '../../api';

export const USER_AUTO_LOGIN = 'USER_AUTO_LOGIN';
export const USER_GET = 'USER_GET';
export const USER_GET_FINANCIALS = 'USER_GET_FINANCIALS';
export const USER_GET_CREDIT_SCORES = 'USER_GET_CREDIT_SCORES';
export const USER_SAVE_CREDIT_SCORE = 'USER_SAVE_CREDIT_SCORE';
export const USER_ENABLE_ALERTS = 'USER_ENABLE_ALERTS';
export const USER_DISABLE_ALERTS = 'USER_DISABLE_ALERTS';
export const USER_SAVE_REFERRAL = 'USER_SAVE_REFERRAL';
export const USER_REFRESH_FINANCIALS = 'USER_REFRESH_FINANCIALS';
export const USER_REFRESH_FINANCIALS_DELAY = 'USER_REFRESH_FINANCIALS_DELAY';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_RESET_PASSWORD_CREATE = 'USER_RESET_PASSWORD_CREATE';
export const USER_RESET_PASSWORD_UPDATE = 'USER_RESET_PASSWORD_UPDATE';
export const USER_UPDATE_DEFAULT_ACCOUNT = 'USER_UPDATE_DEFAULT_ACCOUNT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_PAYOFFS = 'USER_PAYOFFS';
export const USER_UPDATE_CARD = 'USER_UPDATE_CARD';
export const USER_GET_CARDS = 'USER_GET_CARDS';
export const USER_GET_CARD = 'USER_GET_CARD';
export const USER_INSTITUTIONS = 'USER_INSTITUTIONS';
export const USER_CLEAR_AUTH_STATUS = 'USER_CLEAR_AUTH_STATUS';
export const USER_SET_TRIGGER_PLAID = 'USER_SET_TRIGGER_PLAID';
export const USER_BANK_ACCOUNT = 'USER_BANK_ACCOUNT';
export const USER_BANK_ACCOUNTS = 'USER_BANK_ACCOUNTS';
export const USER_SAVE_DETAILS = 'USER_SAVE_DETAILS';
export const USER_GET_ADDRESS = 'USER_GET_ADDRESS';
export const USER_UPDATE_DETAILS = 'USER_UPDATE_DETAILS';
export const USER_UPDATE_REPAYMENT_METHOD = 'USER_UPDATE_REPAYMENT_METHOD';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const USER_GET_ACCOUNT_STATUS = 'USER_GET_ACCOUNT_STATUS';
export const USER_GET_MEMBERSHIP = 'USER_GET_MEMBERSHIP';
export const USER_GET_ACCOUNT_STATEMENTS = 'USER_GET_ACCOUNT_STATEMENTS';
export const USER_GET_NOTIFICATION_SETTINGS = 'USER_GET_NOTIFICATION_SETTINGS';
export const USER_UPDATE_NOTIFICATION_SETTING =
  'USER_UPDATE_NOTIFICATION_SETTING';
export const SESSION_KEEP_ALIVE = 'SESSION_KEEP_ALIVE';
export const USER_LOGTRACE = 'USER_LOGTRACE';
export const USER_GET_AGREEMENTS = 'USER_GET_AGREEMENTS'; // no reducer
export const USER_UPDATE_AGREEMENTS = 'USER_UPDATE_AGREEMENTS'; // no reducer
export const USER_CREATE_BANK_TRANSFER = 'USER_CREATE_BANK_TRANSFER'; // no reducer
export const USER_CREATE_CARD_PRODUCT = 'USER_CREATE_CARD_PRODUCT'; // no reducer
export const USER_SEQUIN_PHONE_VERIFICATION_START =
  'USER_SEQUIN_PHONE_VERIFICATION_START'; // no reducer
export const USER_UNIT_PHONE_VERIFICATION_START =
  'USER_UNIT_PHONE_VERIFICATION_START'; // no reducer
export const USER_SEQUIN_PHONE_VERIFICATION_FINISH =
  'USER_SEQUIN_PHONE_VERIFICATION_FINISH';
export const USER_UNIT_PHONE_VERIFICATION_FINISH =
  'USER_UNIT_PHONE_VERIFICATION_FINISH'; // no reducer
export const USER_GET_APPLICATION = 'USER_GET_APPLICATION'; // no reducer
export const USER_GET_LIABILITY_ACCOUNTS = 'USER_GET_LIABILITY_ACCOUNTS';
export const USER_AUTH_AUTHORIZE = 'USER_AUTH_AUTHORIZE'; // no reducer
export const USER_GET_LIABILITY_RECOMMENDATIONS =
  'USER_GET_LIABILITY_RECOMMENDATIONS';
export const USER_GET_CREDIT_PAYOFF_CONFIG = 'USER_GET_CREDIT_PAYOFF_CONFIG';
export const USER_UPDATE_CREDIT_PAYOFF_CONFIG =
  'USER_UPDATE_CREDIT_PAYOFF_CONFIG';
export const USER_WRITE_SIMULATED_DEBT_PAYOFF_DATA =
  'USER_WRITE_SIMULATED_DEBT_PAYOFF_DATA';
export const USER_GET_MEMBERSHIP_PLANS = 'USER_GET_MEMBERSHIP_PLANS';
export const USER_STRIPE_CHECKOUT = 'USER_STRIPE_CHECKOUT'; // no reducer
export const USER_STRIPE_PORTAL = 'USER_STRIPE_PORTAL'; // no reducer
export const USER_STRIPE_SUBSCRIPTION = 'USER_STRIPE_PAYMENT_INTENT'; // no reducer
export const USER_STRIPE_PAYMENT_SUCCEEDED = 'USER_STRIPE_PAYMENT_SUCCEEDED'; // no reducer
export const USER_GET_CANCELLATION_REASONS = 'USER_GET_CANCELLATION_REASONS'; // no reducer
export const USER_CANCEL_SUBSCRIPTION = 'USER_CANCEL_SUBSCRIPTION'; // no reducer
export const USER_RESUME_SUBSCRIPTION = 'USER_RESUME_SUBSCRIPTION'; // no reducer
export const USER_GET_REFERRER = 'USER_GET_REFERRER';
export const USER_GET_PUBLIC_PLANS = 'USER_GET_PUBLIC_PLANS';
export const USER_SKIP_BANK_TRANSFER = 'USER_SKIP_BANK_TRANSFER'; // no reducer
export const USER_UPDATE_LIABILITY_SETTINGS = 'USER_UPDATE_LIABILITY_SETTINGS';
export const USER_GET_DOWNGRADE_PRODUCT = 'USER_GET_DOWNGRADE_PRODUCT'; // no reducer
export const USER_CLOSE_ACCOUNT = 'USER_CLOSE_ACCOUNT'; // no reducer
export const USER_GET_ATTRIBUTIIION_OPTIONS = 'USER_GET_ATTRIBUTIIION_OPTIONS'; // no reducer
export const USER_CREATE_ATTRIBUTION = 'USER_CREATE_ATTRIBUTION'; // no reducer

// token provided from client storage
export function autoLogin({ token }) {
  return authenticated({
    type: USER_AUTO_LOGIN,
    func: api.user.autoLogin,
    params: token ? { token } : {}
  });
}

// export function autoLogin() {
//   return (dispatch, getState) => {
//     let token = getState().user.token

//     return dispatch({
//       type: USER_AUTO_LOGIN,
//       payload: api.user.autoLogin({ token }).then((res) => {
//         return {
//           user: res
//         }
//       })
//     })
//   }
// }

// export function autoLogin({ token }) {
//   return {
//     type: USER_AUTO_LOGIN,
//     payload: api.user.autoLogin({ token }).then((res) =>
//       api.user.getFinancials({ token }).then((financialsRes) =>
//         api.user
//           .getCreditScores({ token, userId: res?.id })
//           .then((creditScoresRes) => {
//             return {
//               user: res,
//               financials: financialsRes,
//               creditScores: creditScoresRes
//             };
//           })
//       )
//     )
//   };
// }

export function getUser() {
  return authenticated({
    type: USER_GET,
    func: api.user.autoLogin,
    params: {}
  });
}

export function getFinancials() {
  return authenticated({
    type: USER_GET_FINANCIALS,
    func: api.user.getFinancials,
    params: {}
  });
}

export function getCreditScores({ userId }) {
  return authenticated({
    type: USER_GET_CREDIT_SCORES,
    func: api.user.getCreditScores,
    params: { userId }
  });
}

export function updateUser({ userId, params }) {
  return authenticated({
    type: USER_UPDATE,
    func: api.user.update,
    params: { userId, params }
  });
}

export function updateRepaymentMethod({ method }) {
  return authenticated({
    type: USER_UPDATE_REPAYMENT_METHOD,
    func: api.user.updateRepaymentMethod,
    params: { method }
  });
}

export function updateLiabilitySettings({ settings }) {
  return authenticated({
    type: USER_UPDATE_LIABILITY_SETTINGS,
    func: api.user.updateLiabilitySettings,
    params: { settings }
  });
}

export function updateLiability({ userAccountId, params }) {
  return authenticated({
    type: USER_UPDATE_CARD,
    func: api.user.updateLiability,
    params: { userAccountId, params }
  });
}

// deprecated
export function getCards({ userId }) {
  return authenticated({
    type: USER_GET_CARDS,
    func: api.user.getCards,
    params: { userId }
  });
}

export function getLiabilityAccounts() {
  return authenticated({
    type: USER_GET_LIABILITY_ACCOUNTS,
    func: api.user.getLiabilityAccounts,
    params: {}
  });
}

export function getLiabilityRecommendations() {
  return authenticated({
    type: USER_GET_LIABILITY_RECOMMENDATIONS,
    func: api.user.getLiabilityRecommendations,
    params: {}
  });
}

export function getCard() {
  return authenticated({
    type: USER_GET_CARD,
    func: api.card.getCard,
    params: {}
  });
}

export function getPayoffs({ userId }) {
  return authenticated({
    type: USER_PAYOFFS,
    func: api.user.getPayoffs,
    params: { userId }
  });
}

export function getInstitutions() {
  return authenticated({
    type: USER_INSTITUTIONS,
    func: api.user.getInstitutions,
    params: {}
  });
}

export function getBankAccount() {
  return authenticated({
    type: USER_BANK_ACCOUNT,
    func: api.user.getBankAccount,
    params: {}
  });
}

export function getBankAccounts() {
  return authenticated({
    type: USER_BANK_ACCOUNTS,
    func: api.user.getBankAccounts,
    params: {}
  });
}

export function toggleAlerts({ enableAlerts }) {
  return enableAlerts
    ? authenticated({
        type: USER_ENABLE_ALERTS,
        func: api.user.enableAlerts,
        params: {}
      })
    : authenticated({
        type: USER_DISABLE_ALERTS,
        func: api.user.disableAlerts,
        params: {}
      });
}

export function saveCreditScore({ score }) {
  return authenticated({
    type: USER_SAVE_CREDIT_SCORE,
    func: api.user.saveCreditScore,
    params: { score }
  });
}

export function updateDefaultAccountId({ accountId }) {
  return authenticated({
    type: USER_UPDATE_DEFAULT_ACCOUNT,
    func: api.user.updateDefaultAccountId,
    params: { accountId }
  });
}

export function saveReferral({ email, first_name }) {
  return authenticated({
    type: USER_SAVE_REFERRAL,
    func: api.user.saveReferral,
    params: { email, first_name }
  });
}

export function refreshFinancials({ userId, delay }) {
  return delay
    ? authenticated({
        type: USER_REFRESH_FINANCIALS_DELAY,
        func: api.user.refreshFinancialsDelay,
        params: { userId, delay }
      })
    : authenticated({
        type: USER_REFRESH_FINANCIALS,
        func: api.user.refreshFinancials,
        params: { userId }
      });
}

export function login({ email, password }) {
  return {
    type: USER_LOGIN,
    payload: api.user.login({ email, password })
  };
}

export function logout() {
  return authenticated({
    type: USER_LOGOUT,
    func: api.user.logout
  });
}

export function setTriggerPlaid(value) {
  return {
    type: USER_SET_TRIGGER_PLAID,
    payload: value
  };
}

export function register({
  email,
  firstName,
  lastName,
  password,
  phoneNumber,
  flow,
  source,
  inviteCode,
  cpId,
  referrer,
  referringDomain,
  landingPagePath
}) {
  return {
    type: USER_REGISTER,
    payload: api.user.register({
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      phone_number: phoneNumber,
      flow,
      source,
      invite_code: inviteCode,
      cp_id: cpId,
      referrer,
      referringDomain,
      landingPagePath
    })
  };
}

export function resetPasswordCreate({ email }) {
  return {
    type: USER_RESET_PASSWORD_CREATE,
    payload: api.user.resetPasswordCreate({ email })
  };
}

export function resetPasswordUpdate({ password, confirmPassword, digest }) {
  return {
    type: USER_RESET_PASSWORD_UPDATE,
    payload: api.user.resetPasswordUpdate({ password, confirmPassword, digest })
  };
}

export function clearPlaidAuthStatus({ plaidAuthId, plaidBankAccountId }) {
  return authenticated({
    type: USER_CLEAR_AUTH_STATUS,
    func: api.plaid.clearPlaidAuthStatus,
    params: { plaidAuthId, plaidBankAccountId }
  });
}

export function getAddress() {
  return authenticated({
    type: USER_GET_ADDRESS,
    func: api.user.getAddress,
    params: {}
  });
}

export function updateUserDetails({
  firstName,
  lastName,
  birthdate,
  ssn,
  address1,
  address2,
  locality,
  region,
  postalCode
}) {
  return authenticated({
    type: USER_UPDATE_DETAILS,
    func: api.user.updateUserDetails,
    params: {
      firstName,
      lastName,
      birthdate,
      ssn,
      address1,
      address2,
      locality,
      region,
      postalCode
    }
  });
}

export function updateNotification({ id, seen, ack }) {
  return authenticated({
    type: UPDATE_NOTIFICATION,
    func: api.user.updateNotification,
    params: { id, seen, ack }
  });
}

export function getAccountStatus() {
  return authenticated({
    type: USER_GET_ACCOUNT_STATUS,
    func: api.user.getAccountStatus,
    params: {}
  });
}

export function getMembership() {
  return authenticated({
    type: USER_GET_MEMBERSHIP,
    func: api.user.getMembership,
    params: {}
  });
}

export function cancelMembership({ reason, position, userCancelText }) {
  return authenticated({
    type: USER_CANCEL_SUBSCRIPTION,
    func: api.user.cancelMembership,
    params: { reason, position, userCancelText }
  });
}

export function resumeMembership() {
  return authenticated({
    type: USER_RESUME_SUBSCRIPTION,
    func: api.user.resumeMembership,
    params: {}
  });
}

export function closeAccount() {
  return authenticated({
    type: USER_CLOSE_ACCOUNT,
    func: api.user.closeAccount,
    params: {}
  });
}

export function getAccountStatements() {
  return authenticated({
    type: USER_GET_ACCOUNT_STATEMENTS,
    func: api.user.getAccountStatements,
    params: {}
  });
}

export function getNotificationSettings() {
  return authenticated({
    type: USER_GET_NOTIFICATION_SETTINGS,
    func: api.user.getNotificationSettings,
    params: {}
  });
}

export function updateNotificationSetting({ settingId, value, userInput }) {
  return authenticated({
    type: USER_UPDATE_NOTIFICATION_SETTING,
    func: api.user.updateNotificationSetting,
    params: { settingId, value, userInput }
  });
}

export function sessionKeepAlive({ timeout }) {
  return authenticated({
    type: SESSION_KEEP_ALIVE,
    func: api.user.sessionKeepAlive,
    params: { timeout }
  });
}

export function logTrace({ path, payload }) {
  return authenticated({
    type: USER_LOGTRACE,
    func: api.user.logTrace,
    params: { path, payload }
  });
}

export function getAgreements({ cp_id, show }) {
  return authenticated({
    type: USER_GET_AGREEMENTS,
    func: api.user.getAgreements,
    params: { cp_id, show }
  });
}

export function updateAgreements({ agreements }) {
  return authenticated({
    type: USER_UPDATE_AGREEMENTS,
    func: api.user.updateAgreements,
    params: { agreements }
  });
}

export function createBankTransfer({
  counterpartyId,
  depositAccountId,
  amount,
  isCredit,
  description,
  scheduledDate,
  selectedFrequency,
  // dayOfWeek,
  // dayOfMonth,
  endDate,
  totalTransfers,
  isPreview
}) {
  return authenticated({
    type: USER_CREATE_BANK_TRANSFER,
    func: api.unit.createBankTransfer,
    params: {
      counterpartyId,
      depositAccountId,
      amount,
      isCredit,
      description,
      scheduledDate,
      selectedFrequency,
      // dayOfWeek,
      // dayOfMonth,
      endDate,
      totalTransfers,
      isPreview
    }
  });
}

export function skipBankTransfer() {
  return authenticated({
    type: USER_SKIP_BANK_TRANSFER,
    func: api.user.skipBankTransfer,
    params: {}
  });
}

export function createCardProduct({ cpId }) {
  return authenticated({
    type: USER_CREATE_CARD_PRODUCT,
    func: api.user.createCardProduct,
    params: { cpId }
  });
}

export function sequinPhoneVerificationStart() {
  return authenticated({
    type: USER_SEQUIN_PHONE_VERIFICATION_START,
    func: api.user.phoneVerificationStart
  });
}

export function unitPhoneVerificationStart() {
  return authenticated({
    type: USER_UNIT_PHONE_VERIFICATION_START,
    func: api.unit.phoneVerificationStart
  });
}

export function sequinPhoneVerificationFinish({ verificationCode }) {
  return authenticated({
    type: USER_SEQUIN_PHONE_VERIFICATION_FINISH,
    func: api.user.phoneVerificationFinish,
    params: { verificationCode }
  });
}

export function unitPhoneVerificationFinish({
  verificationId,
  verificationCode,
  scope
}) {
  return authenticated({
    type: USER_UNIT_PHONE_VERIFICATION_FINISH,
    func: api.unit.phoneVerificationFinish,
    params: { verificationId, verificationCode, scope }
  });
}

export function getApplication() {
  return authenticated({
    type: USER_GET_APPLICATION,
    func: api.user.getApplication
  });
}

export function oauthAuthorize({ params }) {
  return authenticated({
    type: USER_AUTH_AUTHORIZE,
    func: api.user.oauthAuthorize,
    params: { params }
  });
}

// credit tool
export function getPayoffConfig() {
  return authenticated({
    type: USER_GET_CREDIT_PAYOFF_CONFIG,
    func: api.user.getPayoffConfig
  });
}

export function updatePayoffConfig({ payment, method }) {
  console.log('updatePayoffConfig', payment, method);
  return authenticated({
    type: USER_UPDATE_CREDIT_PAYOFF_CONFIG,
    func: api.user.updatePayoffConfig,
    params: { payment, method }
  });
}

// local Redux update only
export function storeSimulatedDebtPayoffData(data) {
  return {
    type: USER_WRITE_SIMULATED_DEBT_PAYOFF_DATA,
    payload: data
  };
}

export function getMembershipPlans({ productName }) {
  return authenticated({
    type: USER_GET_MEMBERSHIP_PLANS,
    func: api.user.getMembershipPlans,
    params: { productName }
  });
}

export function getPublicPlans({ source, productName }) {
  return {
    type: USER_GET_PUBLIC_PLANS,
    payload: api.user.getPublicPlans({ source, productName })
  };
}

// stripe checkout
export function stripeCreateCheckoutSession({ plan }) {
  return authenticated({
    type: USER_STRIPE_CHECKOUT,
    func: api.user.stripeCreateCheckoutSession,
    params: { plan }
  });
}

// stripe billing portal
export function stripeCreatePortalSession() {
  return authenticated({
    type: USER_STRIPE_PORTAL,
    func: api.user.stripeCreatePortalSession,
    params: {}
  });
}

// stripe elements
export function stripeCreateSubscription({ plan }) {
  return authenticated({
    type: USER_STRIPE_SUBSCRIPTION,
    func: api.user.stripeCreateSubscription,
    params: { plan }
  });
}

export function stripePaymentSucceeded({ paymentIntentId }) {
  return authenticated({
    type: USER_STRIPE_PAYMENT_SUCCEEDED,
    func: api.user.stripePaymentSucceeded,
    params: { paymentIntentId }
  });
}

export function getCancellationReasons() {
  return authenticated({
    type: USER_GET_CANCELLATION_REASONS,
    func: api.user.getCancellationReasons,
    params: {}
  });
}

export function getDowngradeProduct() {
  return authenticated({
    type: USER_GET_DOWNGRADE_PRODUCT,
    func: api.user.getDowngradeProduct,
    params: {}
  });
}

export function getReferrer({ referralCode }) {
  return {
    type: USER_GET_REFERRER,
    payload: api.user.getReferrer({ referralCode })
  };
}

export function getAttributionOptions() {
  return authenticated({
    type: USER_GET_ATTRIBUTIIION_OPTIONS,
    func: api.user.getAttributionOptions,
    params: {}
  });
}

export function createUserAttribution({ attributionOptionId, additionalInfo }) {
  return authenticated({
    type: USER_CREATE_ATTRIBUTION,
    func: api.user.createUserAttribution,
    params: { attributionOptionId, additionalInfo }
  });
}
