import './logger';

import React, { Component } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import {
  faCashback,
  faChatBubble,
  faCreditCard,
  faGear,
  faGift
} from './assets/icons';
import Router from './shared/Router';

import './App.scss';

library.add(
  fas,
  far,
  faSlack,
  faCashback,
  faCreditCard,
  faGift,
  faGear,
  faChatBubble
);

class App extends Component {
  render() {
    return <Router />;
  }
}

export default App;
