import * as React from 'react';

import PropTypes from 'prop-types';
import { BsTools } from 'react-icons/bs';
import { IoIosMore } from 'react-icons/io';
import {
  IoCardOutline,
  IoClose,
  IoGiftOutline,
  IoHelpCircleOutline,
  IoLogOutOutline,
  IoPeopleOutline,
  IoSchool,
  IoSettingsOutline
} from 'react-icons/io5';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { logout } from '../store/actions/user.actions';

import './layouts/SequinLayout.scss';

const Bottombar = ({ onLogout }) => {
  const [isMenuExpanded, setIsMenuExpanded] = React.useState(false);
  const navigate = useNavigate();

  const logoutUser = () => {
    onLogout();
    navigate('/');
  };

  return (
    <div className="sequin-bottom-nav">
      <div className={`nav-items ${isMenuExpanded ? 'expanded' : ''}`}>
        {isMenuExpanded ? (
          <>
            <div className="nav-item">
              <NavLink to="/faq" end aria-label="FAQ">
                <IoHelpCircleOutline size={'32px'} />
                <div className="my-1">FAQ</div>
              </NavLink>
            </div>
            <div className="nav-item">
              <NavLink to="/card/refer" end aria-label="Invite Friends">
                <IoPeopleOutline size={'32px'} />
                <div className="my-1">Invite Friends</div>
              </NavLink>
            </div>
            <div className="nav-item">
              <NavLink to="/card/dashboard/perks" end aria-label="Perks">
                <IoGiftOutline size={'32px'} />
                <div className="my-1">Perks</div>
              </NavLink>
            </div>
            <div className="nav-item">
              <NavLink to="/rewards" end aria-label="Rewards">
                <IoGiftOutline size={'32px'} />
                <div className="my-1">Rewards</div>
              </NavLink>
            </div>

            <div className="nav-item">
              <NavLink to="/card/options" end aria-label="Account">
                <IoSettingsOutline size={'32px'} />
                <div className="my-1">Settings</div>
              </NavLink>
            </div>

            <div className="nav-item">
              <NavLink to="/logout" end aria-label="Logout">
                <IoLogOutOutline size={'32px'} />
                <div className="my-1">Logout</div>
              </NavLink>
            </div>
            <div className="nav-item">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsMenuExpanded(!isMenuExpanded);
                }}
              >
                <IoClose size={'32px'} />
                <div className="my-1">Close Menu</div>
              </a>
            </div>
          </>
        ) : (
          <>
            {window.location.pathname !== '/home' && (
              <div className="nav-item">
                <NavLink to="/" end aria-label="Dashboard">
                  <IoCardOutline size={'32px'} />
                  <div className="my-1">Dashboard</div>
                </NavLink>
              </div>
            )}
            <div className="nav-item">
              <NavLink
                to="/card/dashboard/university"
                end
                aria-label="University"
              >
                <IoSchool size={'32px'} />
                <div className="my-1">University</div>
              </NavLink>
            </div>
            <div className="nav-item">
              <NavLink to="/financial-tools" end aria-label="Financial Tools">
                <BsTools size={'32px'} />
                <div className="my-1">Tools</div>
              </NavLink>
            </div>
            <div className="nav-item">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsMenuExpanded(!isMenuExpanded);
                }}
              >
                <IoIosMore size={'32px'} />
                <div className="my-1">More</div>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Bottombar.propTypes = {
  onLogout: PropTypes.func,
  user: PropTypes.shape({
    cardProduct: PropTypes.shape({
      product_type: PropTypes.string
    }),
    details: PropTypes.shape({
      cardProductType: PropTypes.string
    })
  })
};

export default connect((state) => ({ user: state.user }), { onLogout: logout })(
  Bottombar
);
