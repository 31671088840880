//import { APPCUES_USER_ID_PREFIX } from "../api/constants";

const analyticsMiddleware = (store) => (next) => (action) => {
  let appcues = window.Appcues;

  // if (action.type === '@@router/LOCATION_CHANGE') {
  //   const nextPage = `${action.payload.pathname}`;
  //   amplitude.getInstance().logEvent('PAGE_VIEW', {'path': nextPage});
  // }

  // if (eventTracking[action.type]) {
  //   trackEvent(eventTracking[action.type], store.getState());
  // }

  let preState = store.getState();

  let result = next(action);

  let postState = store.getState();

  let needsIdentify = false;
  let userFlags = {};
  if (
    preState?.user?.payoffs &&
    postState?.user?.payoffs &&
    preState.user.payoffs.length == 0 &&
    postState.user.payoffs.length > 0
  ) {
    needsIdentify = true;
    userFlags.hasPayoffs = true;
  }

  if (
    preState?.user?.financials &&
    postState?.user?.financials &&
    preState.user.financials.length == 0 &&
    postState.user.financials.length > 0
  ) {
    needsIdentify = true;
    userFlags.connectedPlaid = true;
  }
  if (
    preState?.user?.creditScores &&
    postState?.user?.creditScores &&
    preState.user.creditScores.length == 0 &&
    postState.user.creditScores.length > 0
  ) {
    needsIdentify = true;
    userFlags.hasCreditScores = true;
  }

  let user_id = postState?.user?.details?.id;

  // console.log(
  //   `MW: payoffs [${preState?.user?.payoffs?.length}, ${postState?.user?.payoffs?.length}] financials: [${preState?.user?.financials?.length}, ${postState?.user?.financials?.length}], scores: [${preState?.user?.creditScores?.length}, ${postState?.user?.creditScores?.length}]`
  // );
  // console.log(
  //   `MW: Object.keys(userFlags).length is ${
  //     Object.keys(userFlags).length
  //   } and user_id is ${user_id}`
  // );
  if (appcues && needsIdentify && user_id) {
    let params = {
      hasPayoffs: !!postState?.user?.payoffs?.length,
      connectedPlaid: !!postState?.user?.financials?.length,
      hasCreditScores: !!postState?.user?.creditScores?.length
    };
    // console.log(
    //   `Updating appcues: { hasPayoffs: ${params.hasPayoffs}, connectedPlaid: ${params.connectedPlaid}, hasCreditScores: ${params.hasCreditScores} }`
    // );
    //appcues.identify(user_id, userFlags);
    appcues.identify(user_id /* + parseInt(APPCUES_USER_ID_PREFIX)*/, params);
  }

  return result;
};

export default analyticsMiddleware;
