import { BASE_URL } from './constants';

export const getLinkToken = async ({ token, isAuth, flow }) => {
  const response = await fetch(
    `${BASE_URL}/plaid/link_token?${isAuth ? 'auth=t' : 'auth=f'}${
      flow ? `&flow=${flow}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getLinkTokenUpdate = async ({
  token,
  plaidAuthId,
  plaidBankAccountId
}) => {
  let query = plaidBankAccountId
    ? `plaid_bank_account_id=${plaidBankAccountId}`
    : `plaid_auth_id=${plaidAuthId}`;
  const response = await fetch(`${BASE_URL}/plaid/link_token_update?${query}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getAccessToken = async ({ publicToken, token, metadata }) => {
  const response = await fetch(`${BASE_URL}/plaid/public_token`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      public_token: publicToken,
      metadata: metadata
    })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const exchangeAuthToken = async ({ publicToken, token, metadata }) => {
  const response = await fetch(`${BASE_URL}/plaid/exchange_auth_token`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      public_token: publicToken,
      metadata: metadata
    })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const clearPlaidAuthStatus = async ({
  token,
  plaidAuthId,
  plaidBankAccountId
}) => {
  const response = await fetch(`${BASE_URL}/plaid/clear_auth_status`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      plaid_auth_id: plaidAuthId,
      plaid_bank_account_id: plaidBankAccountId
    })
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};
