// const BASE_URL =
//   process.env.NODE_ENV === "production"
//     ? "https://app.sequincard.com"
//     : "http://localhost:3001";

import { BASE_URL } from './constants';

// const request = async (path, params) => {
//   const response = await fetch(path, params).then((r) => {
//     if (!r.ok) {
//       throw r;
//     } else {
//       return r;
//     }
//   });
//   const jsonResponse = await response.json();
//   return jsonResponse;
// };

// export const autoLogin = async ({ token }) => {
//   const response = await request(`${BASE_URL}/auto_login`, {
//     method: 'GET',
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
//   return response;
// };

export const autoLogin = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/auto_login`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    if (!r.ok) {
      throw r.statusText;
    } else {
      return r;
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const login = async ({ email, password }) => {
  const response = await fetch(`${BASE_URL}/login`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const register = async ({
  email,
  first_name,
  last_name,
  password,
  phone_number,
  flow,
  source,
  invite_code,
  cp_id,
  referrer,
  referringDomain,
  landingPagePath
}) => {
  const response = await fetch(`${BASE_URL}/users`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      first_name,
      last_name,
      password,
      phone_number,
      signup_flow: flow,
      source,
      invite_code,
      cp_id,
      referrer,
      referring_domain: referringDomain,
      landing_page_path: landingPagePath
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  // console.log("json resp:", jsonResponse)
  return jsonResponse;
};

export const logout = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const resetPasswordCreate = async ({ email }) => {
  const response = await fetch(`${BASE_URL}/password_resets`, {
    method: 'POST',
    body: JSON.stringify({
      email
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const resetPasswordUpdate = async ({
  password,
  confirmPassword,
  digest
}) => {
  const response = await fetch(`${BASE_URL}/password_resets/${digest}`, {
    method: 'PATCH',
    body: JSON.stringify({
      password,
      confirmPassword
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getFinancials = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/financials/all`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const saveCreditScore = async ({ token, score }) => {
  const response = await fetch(`${BASE_URL}/credit_score`, {
    method: 'POST',
    body: JSON.stringify({ score }),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateDefaultAccountId = async ({ token, accountId }) => {
  const response = await fetch(`${BASE_URL}/users/default_account_id`, {
    method: 'POST',
    body: JSON.stringify({ accountId }),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const saveReferral = async ({ token, email, first_name }) => {
  const response = await fetch(`${BASE_URL}/invites`, {
    method: 'POST',
    body: JSON.stringify({ email, first_name }),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const update = async ({ token, userId, params }) => {
  const response = await fetch(`${BASE_URL}/users/${userId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// deprecated
export const getCards = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/user_accounts`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateLiabilitySettings = async ({ token, settings }) => {
  const response = await fetch(`${BASE_URL}/user_accounts/settings`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ settings })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// deprecated
export const updateLiability = async ({ token, userAccountId, params }) => {
  const response = await fetch(`${BASE_URL}/user_accounts/${userAccountId}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getLiabilityAccounts = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/user_accounts`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getLiabilityRecommendations = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/user_accounts/recommendations`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const enableAlerts = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/alerts/enable`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const disableAlerts = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/alerts/disable`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const recordAddress = async ({
  token,
  is_billing,
  address1,
  address2,
  city,
  state,
  zip,
  country_code
}) => {
  const response = await fetch(`${BASE_URL}/users/address`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      is_billing,
      address1,
      address2,
      city,
      state,
      postal_code: zip,
      country_code
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getCreditScores = async ({ token, userId }) => {
  const response = await fetch(`${BASE_URL}/credit_score/all`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getPayoffs = async ({ token, userId }) => {
  const response = await fetch(`${BASE_URL}/financials/payoffs`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getInstitutions = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/institutions`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getBankAccount = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/bank_account`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getBankAccounts = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/bank_accounts`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const refreshFinancials = async ({ token, userId }) => {
  const response = await fetch(`${BASE_URL}/financials/refresh`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
};

export const refreshFinancialsDelay = async ({ token, userId, delay }) => {
  const response = await fetch(`${BASE_URL}/financials/refresh/${delay}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response;
};

export const logTrace = async ({ token, path, payload }) => {
  const response = await fetch(`${BASE_URL}/users/trace`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      path,
      payload
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getAddress = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/address`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateUserDetails = async ({
  token,
  firstName,
  lastName,
  birthdate,
  ssn,
  address1,
  address2,
  locality,
  region,
  postalCode
}) => {
  let params = {};
  // only accept truthy values
  if (firstName) {
    params['first_name'] = firstName;
  }
  if (lastName) {
    params['last_name'] = lastName;
  }
  if (birthdate) {
    params['birthdate'] = birthdate;
  }
  if (ssn) {
    params['ssn'] = ssn;
  }
  if (address1) {
    params['address1'] = address1;
  }
  if (address2) {
    params['address2'] = address2;
  }
  if (locality) {
    params['locality'] = locality;
  }
  if (region) {
    params['region'] = region;
  }
  if (postalCode) {
    params['postal_code'] = postalCode;
  }

  const response = await fetch(`${BASE_URL}/users/details`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// Stripe

export const stripeCreateCheckoutSession = async ({ token, plan }) => {
  const response = await fetch(`${BASE_URL}/stripe/checkout-session`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ plan })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const stripeCreateAchSubscription = async ({ token, plan }) => {
  const response = await fetch(`${BASE_URL}/stripe/ach-subscription`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ plan })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const stripeCheckForNewSubscrion = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/stripe/check-new-subscription`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const stripeCreatePortalSession = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/stripe/portal-session`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// for stripe elements
export const stripeCreateSubscription = async ({ token, plan }) => {
  const response = await fetch(`${BASE_URL}/stripe/subscription`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ plan })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const stripePaymentSucceeded = async ({ token, paymentIntentId }) => {
  const response = await fetch(`${BASE_URL}/stripe/payment-succeeded`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ payment_intent_id: paymentIntentId })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getMembershipPlans = async ({ token, productName }) => {
  const response = await fetch(
    `${BASE_URL}/stripe/plans${productName ? '?product_name=' + productName : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getPublicPlans = async ({ source, productName }) => {
  let params = {};
  if (source) {
    params.source = source;
  }
  if (productName) {
    params.product_name = productName;
  }

  const searchParams = new URLSearchParams(params).toString();

  const response = await fetch(
    `${BASE_URL}/stripe/public_plans${searchParams ? '?' + searchParams : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateRepaymentMethod = async ({ token, method }) => {
  const response = await fetch(`${BASE_URL}/users/payment_method`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ method })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateNotification = async ({ token, id, seen, ack }) => {
  const response = await fetch(`${BASE_URL}/users/notifications/${id}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ seen, ack })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// deprecate
export const checkOnboardStatus = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/onboard_status`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getAccountStatus = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/account_status`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const cancelMembership = async ({
  token,
  reason,
  position,
  userCancelText
}) => {
  const response = await fetch(`${BASE_URL}/stripe/cancel-membership`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ reason, position, user_cancel_text: userCancelText })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const resumeMembership = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/stripe/resume-membership`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const closeAccount = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/user/close-account`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};


export const getMembership = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/membership`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getApplication = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/application`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getAgreements = async ({ token, cp_id, show }) => {
  const params = cp_id ? `?card_product_id=${cp_id}` : '';

  let data = {}
  if (cp_id) {
    data.card_product_id = cp_id;
  }
  if (show) {
    data.show = show;
  }
  const queryParams = new URLSearchParams(data);
  const query = queryParams.toString();

  const response = await fetch(`${BASE_URL}/users/agreements${query ? '?' + query : ''}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateAgreements = async ({ token, agreements }) => {
  const response = await fetch(`${BASE_URL}/users/agreements`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ agreements })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const phoneVerificationStart = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/start-phone-verification`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const phoneVerificationFinish = async ({ token, verificationCode }) => {
  const response = await fetch(`${BASE_URL}/users/finish-phone-verification`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ verification_code: verificationCode })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const createCardProduct = async ({ token, cpId }) => {
  const response = await fetch(`${BASE_URL}/cards/create-product`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ cp_id: cpId })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getAccountStatements = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/card/account-statements`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getNotificationSettings = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/notification-settings`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updateNotificationSetting = async ({
  token,
  settingId,
  activityId,
  value,
  userInput
}) => {
  const response = await fetch(`${BASE_URL}/users/notification-setting`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      setting_id: settingId,
      activity_id: activityId,
      value,
      user_input: userInput
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const sessionKeepAlive = async ({ token, timeout }) => {
  const response = await fetch(`${BASE_URL}/users/session-keep-alive`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      timeout
    })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const oauthAuthorize = async ({ token, params }) => {
  //const response = await fetch(`${BASE_URL}/oauth/authorize${params ? ('?' + params) : ''}`, {
  const response = await fetch(`${BASE_URL}/oauth/authorize`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// credit tool config
export const getPayoffConfig = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/credit-payoff-config`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const updatePayoffConfig = async ({ token, payment, method }) => {
  const response = await fetch(`${BASE_URL}/users/credit-payoff-config`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ payment, method })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getCancellationReasons = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/cancellation-reasons`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const getDowngradeProduct = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/cards/downgrade-product`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

// referral
export const getReferrer = async ({ referralCode }) => {
  const response = await fetch(
    `${BASE_URL}/users/referrer?code=${referralCode}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const skipBankTransfer = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/users/skip-bank-transfer`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
}

// customer attribution
export const getAttributionOptions = async ({ token }) => {
  const response = await fetch(`${BASE_URL}/attribution-options`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  const jsonResponse = await response.json();
  return jsonResponse;
};

export const createUserAttribution = async ({ token, attributionOptionId, additionalInfo }) => {
  const response = await fetch(`${BASE_URL}/users/attribution`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ attribution_id: attributionOptionId, additional_info: additionalInfo })
  });

  const jsonResponse = await response.json();
  return jsonResponse;
}

