import * as React from 'react';

export const SEQUIN_CARD_NAME = (
  <>
    Sequin Rewards Visa<sup>®</sup> Debit Card
  </>
);
export const SEQUIN_CARD_SHORT_NAME = <>Sequin Rewards Card</>;
export const SEQUIN_CARD_NAME_TEXT = 'Sequin Rewards Visa® Debit Card'; // for text-only case, like <title>
//export const SEQUIN_ACCOUNT_NAME = 'Sequin High-Yield Checking Account';//'Sequin Checking Account';
export const SEQUIN_ACCOUNT_NAME = 'Sequin High-Interest Checking Account';
export const SEQUIN_BANKING = 'Sequin Banking';
export const SEQUIN_GENERIC_ACCOUNT_NAME = 'Sequin high interest checking account'

export const SEQUIN_BASIC_ACCOUNT_NAME = 'Sequin Basic Account';
export const SEQUIN_MEMBERSHIP_ACCOUNT_NAME = 'Sequin Membership Account';

export const SEQUIN_UNIVERSITY_INVITE_URL =
  'https://www.sequin-university.com/invite';
export const SEQUIN_UNIVERSITY_BASE_URL = 'https://www.sequin-university.com';
export const SEQUIN_UNIVERSITY_MANAGE_URL =
  'https://www.sequin-university.com/your-purchases';

export const SEQUIN_FREE_APY_TEXT = '0.86% APY';
export const SEQUIN_APY_TEXT = '3.06% APY';
export const APY_FASTER_X = '43x';
export const SEQUIN_APY_DATE = 'October 6, 2023';
export const NATIONAL_APY_TEXT = '0.07% APY as of October, 2023';
