import * as React from 'react';

import { Spinner } from 'react-bootstrap';

export const LoadingSpinner = ({ children }) => {
  return (
    <div className="center full-height">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
      {children}
    </div>
  );
};
