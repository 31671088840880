export const faChatBubble = {
  prefix: 'fax',
  iconName: 'chat-bubble',
  icon: [
    20,
    20,
    [],
    'e005',
    'M10.0001 1C14.9709 1 19.0003 5.02936 19.0003 10.0001C19.0003 14.9709 14.9709 19.0003 10.0001 19.0003C8.56372 19.0023 7.14784 18.659 5.87178 17.9995L2.42833 18.9598C2.23621 19.0134 2.0333 19.015 1.84037 18.9643C1.64745 18.9137 1.47145 18.8127 1.33041 18.6717C1.18937 18.5306 1.08837 18.3546 1.03774 18.1617C0.987117 17.9688 0.988693 17.7659 1.04231 17.5738L2.00352 14.133C1.34233 12.8558 0.998138 11.4383 1.00001 10.0001C1.00001 5.02936 5.02937 1 10.0001 1ZM10.0001 2.35002C7.97121 2.35002 6.02537 3.15601 4.5907 4.59069C3.15602 6.02536 2.35003 7.9712 2.35003 10.0001C2.35003 11.3232 2.68573 12.5949 3.31574 13.7235L3.45075 13.9665L2.44993 17.5522L6.03829 16.5513L6.28129 16.6863C7.30225 17.254 8.43964 17.5803 9.60625 17.6402C10.7729 17.7001 11.9377 17.4921 13.0114 17.032C14.0852 16.572 15.0393 15.8722 15.8007 14.9862C16.562 14.1003 17.1104 13.0517 17.4037 11.921C17.697 10.7903 17.7275 9.60741 17.4928 8.46309C17.2581 7.31876 16.7645 6.24338 16.0497 5.31941C15.335 4.39543 14.4181 3.64741 13.3695 3.13269C12.3209 2.61798 11.1683 2.35024 10.0001 2.35002ZM7.0751 10.9002H11.1234C11.2944 10.9002 11.459 10.9652 11.584 11.0819C11.709 11.1987 11.7849 11.3585 11.7966 11.5292C11.8083 11.6998 11.7547 11.8685 11.6468 12.0011C11.5389 12.1338 11.3846 12.2206 11.2152 12.2439L11.1234 12.2502H7.0751C6.90408 12.2501 6.73945 12.1852 6.61448 12.0684C6.48952 11.9516 6.41353 11.7918 6.40187 11.6212C6.39021 11.4505 6.44376 11.2819 6.55168 11.1492C6.65961 11.0165 6.81387 10.9298 6.9833 10.9065L7.0751 10.9002H11.1234H7.0751ZM7.0751 7.7501H12.9297C13.1006 7.75038 13.2651 7.81551 13.3899 7.93234C13.5147 8.04917 13.5905 8.209 13.6021 8.37955C13.6136 8.55011 13.56 8.71869 13.4521 8.85127C13.3442 8.98384 13.1899 9.07053 13.0206 9.09383L12.9297 9.10013H7.0751C6.90408 9.10007 6.73945 9.0351 6.61448 8.91835C6.48952 8.80159 6.41353 8.64175 6.40187 8.47112C6.39021 8.3005 6.44376 8.13181 6.55168 7.99914C6.65961 7.86647 6.81387 7.77972 6.9833 7.7564L7.0751 7.7501H12.9297H7.0751Z'
  ]
};
