import { authenticated } from './common';
import api from '../../api';

export const PLAID_GET_LINK_TOKEN = 'PLAID_GET_LINK_TOKEN';
//export const PLAID_GET_LINK_TOKEN_UPDATE = "PLAID_GET_LINK_TOKEN_UPDATE";
export const PLAID_GET_ACCESS_TOKEN = 'PLAID_GET_ACCESS_TOKEN';
export const PLAID_CLEAR_LINK_TOKEN = 'PLAID_CLEAR_LINK_TOKEN';
export const PLAID_EXCHANGE_AUTH_TOKEN = 'PLAID_EXCHANGE_AUTH_TOKEN';

export function getPlaidLinkToken({ isAuth, flow }) {
  return authenticated({
    type: PLAID_GET_LINK_TOKEN,
    func: api.plaid.getLinkToken,
    params: { isAuth, flow }
  });
}

export function clearPlaidLinkToken() {
  return {
    type: PLAID_CLEAR_LINK_TOKEN
  };
}

export function getPlaidLinkTokenUpdate({ plaidAuthId, plaidBankAccountId }) {
  return authenticated({
    type: PLAID_GET_LINK_TOKEN,
    func: api.plaid.getLinkTokenUpdate,
    params: { plaidAuthId, plaidBankAccountId }
  });
}

export function getAccessToken({ publicToken, metadata }) {
  return authenticated({
    type: PLAID_GET_ACCESS_TOKEN,
    func: api.plaid.getAccessToken,
    params: { publicToken, metadata }
  });
}

export function exchangeAuthToken({ publicToken, metadata }) {
  return authenticated({
    type: PLAID_EXCHANGE_AUTH_TOKEN,
    func: api.plaid.exchangeAuthToken,
    params: { publicToken, metadata }
  });
}
