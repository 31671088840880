export const faCreditCard = {
  prefix: 'fax',
  iconName: 'credit-card',
  icon: [
    20,
    20,
    [],
    'e002',
    'M2 8.22228H18H2ZM5.55556 12.6667H6.44444H5.55556ZM10 12.6667H10.8889H10ZM4.66667 16.2223H15.3333C16.0406 16.2223 16.7189 15.9413 17.219 15.4412C17.719 14.9411 18 14.2629 18 13.5556V6.4445C18 5.73725 17.719 5.05898 17.219 4.55888C16.7189 4.05878 16.0406 3.77783 15.3333 3.77783H4.66667C3.95942 3.77783 3.28115 4.05878 2.78105 4.55888C2.28095 5.05898 2 5.73725 2 6.4445V13.5556C2 14.2629 2.28095 14.9411 2.78105 15.4412C3.28115 15.9413 3.95942 16.2223 4.66667 16.2223Z'
  ]
};
